(function () {
	"use strict";

	angular
		.module('smartermail')
		.component('messageCompose', {
			templateUrl: "app/email/compose/compose.component.html",
			controller: messageCompose,
		});

	/* @ngInject */
	function messageCompose($rootScope, $scope, $stateParams, $timeout, $element, $mdDialog, $mdConstant, $translate, $http, $q, $filter, coreData, emailFunctions, emailNavigation,
		spinnerFactory, errorHandling, successHandling, userDataService, userTimeService, toaster, restrictedSenders, apiCategories, coreDataCategories, coreDataContacts,
		themesService, coreDataSettings, coreDataFileStorage, fileInfoProvider, errorMessageService, emailValidationService) {

		const MODE = {
			// anything else is new
			Reply: 1,
			Forward: 2
		};

		const saveInterval = 120000;

		// To explain the -59 for semicolon. Angular material by default for chips checks if the keycode matches one of these in the keyDown method. problem is on foreign keyboards the keycode for
		// semicolon can be a completly different character. So we modifed it to also check keyPress which gives a char code which is 59 for semicolon. ands we use the - and check only against
		// negatives in this keyPress function and let keyDown handle the normal keys.
		const specialSemicolon = -59;

		// https://stackoverflow.com/questions/6462578/alternative-to-regex-match-all-instances-not-inside-quotes
		const regexSplit = /[,;](?=(?:[^"\\]*(?:\\.|"(?:[^"\\]*\\.)*[^"\\]*"))*[^"]*$)/g;

		// Properties ------------------------------------------
		var vm = this;
		var popoutPacket = emailNavigation.parsePopoutPacket($stateParams.packet);
		var skipAskCheck = false;
		var isComposeInitialized = false;
		var isEditorFullyInitialized = false;
		var saveDraftParams = {};
		var hasNewAttachment = false;
		var insertedInlineAttachments = [];
		var messageOversizeToast;
		var attachOversizeToast;
		var isAutoCompleteLoaded = false;
		var spinner = $rootScope.spinner || new spinnerFactory();
		var isCoreDataLoaded = false;

		// Angular-Exposed Properties
		vm.addressSeparatorKeys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.TAB, $mdConstant.KEY_CODE.COMMA, specialSemicolon];
		vm.attachmentGuid = popoutPacket.attachmentGuid || generateGuid();
		vm.autocompleteData = {
			to: { selectedItem: '', searchString: '' },
			cc: { selectedItem: '', searchString: '' },
			bcc: { selectedItem: '', searchString: '' },
		};
		vm.cloudStorageEnabled = false;
		vm.contacts = [];
		vm.editorScope = null;
		vm.fromAddresses = [];
		vm.hasSentMessage = false;
		vm.hideLinkFile = false;
		vm.loaded = false;
		vm.message = null;
		vm.minHeight = 150;
		vm.overSizeLimit = false;
		vm.recipientListBcc = []; //autocomplete chips
		vm.recipientListCc = []; //autocomplete chips
		vm.recipientListTo = []; //autocomplete chips
		vm.reply = { priority: 1, requestReadReceipt: false, requestDeliveryReceipt: false, markForFollowup: false, };
		vm.selectedFromAddress = "";
		vm.selectedSignature = {};
		vm.showCCs = false;
		vm.signatures = {};
		vm.skipDraftCheck = false;
		vm.uploaderData = null;

		// Functions
		vm.attachmentRemoveForwardedFile = attachmentRemoveForwardedFile;
		vm.onRecipientListChanged = onRecipientListChanged;
		vm.closeWindow = closeWindow;
		vm.froalaOnImageInserted = froalaOnImageInserted;
		vm.froalaOnInit = froalaOnInit;
		vm.hasMessageChanged = hasMessageChanged;
		vm.onCancel = scopeCancel;
		vm.onLinkFile = onLinkFile;
		vm.onSaveDraft = onSaveDraft;
		vm.onSend = onSend;
		vm.onSetPriority = onSetPriority;
		vm.onToggleMarkFollowup = onToggleMarkFollowup;
		vm.onToggleRequestDeliveryReceipt = onToggleRequestDeliveryReceipt;
		vm.onToggleRequestReadReceipt = onToggleRequestReadReceipt;
		vm.openRecipientsModal = openRecipientsModal;
		vm.showCCFields = showCCFields;
		vm.uploaderOnCanceled = uploaderOnCanceled;
		vm.uploaderOnFileRemoved = uploaderOnFileRemoved;
		vm.uploaderOnInit = uploaderOnInit;
		vm.uploaderOnPreUpload = uploaderOnPreUpload;
		vm.uploaderOnUploaded = uploaderOnUploaded;
		vm.uploaderOnUploadFailure = uploaderOnUploadFailure;
		$scope.cancel = scopeCancel;
		vm.focusSubject = () => $('#subjectInputCtrl').focus();

		// Throttled Functions
		var debouncedOnFromAddressChanged = _.debounce(onFromAddressChanged, 20);
		var debouncedOnSignatureChanged = _.debounce(onSignatureChanged, 20);
		var throttledDoSendMessage = _.throttle(doSendMessage, 500);
		var throttledOnResize = _.throttle(onResize, 200, { leading: false });
		vm.tryResize = throttledOnResize;
		///////////////////

		vm.$onInit = function () {
			themesService.ensureActivated();
			$element.addClass('message-compose');

			window.addEventListener('beforeunload', windowOnBeforeUnload, { capture: true });
			window.addEventListener("resize", throttledOnResize);
			window.addEventListener("resize.doResize", throttledOnResize);
			$scope.$on("resize.doResize", throttledOnResize);

			init();


		};

		vm.$onDestroy = function () {
			window.removeEventListener("resize", throttledOnResize);
			window.removeEventListener("resize.doResize", throttledOnResize);
		};

		async function init() {
			try {
				spinner.show();

				await userDataService.init();
				const result = await Promise.all([
					userTimeService.init(),
					restrictedSenders.init(),
					apiCategories.getCategories(),
					autocompleteInit()
				]);

				vm.messageCategories = result[2];
				isCoreDataLoaded = true;

				vm.uploaderData = JSON.stringify({ guid: vm.attachmentGuid });
				vm.autoSaveEnabled = coreData.user.settings.userMailSettings.draftAutoSave;

				if (popoutPacket.uid) {
					const isForForward = popoutPacket.reply === 3;
					const results = await Promise.all([
						emailFunctions.loadMessage(popoutPacket.owner, popoutPacket.folder, popoutPacket.uid, isForForward),
						$http.get("~/api/v1/folders/list-email-folders")
					]);

					await processMessage(results[0]);
					processFolderList(results[1]);
				}
				else {
					vm.message = {};
				}

				checkHideLinkFile();
				await setupComposeData();
				await setupAddressesAndSignatures();
				await loadComposeData();

			} catch (err) {
				vm.messageDoesNotExist = true;
				errorHandling.report();
			} finally {
				$scope.$applyAsync(function () { vm.loaded = true; });
				$timeout(throttledOnResize);
				isComposeInitialized = true;
				onEverythingReady();
				spinner.hide();

			}

			// If subject control gets a tab, it should set focus to editor. This is to 
			// workaround a problem with firefox not tabbing correctly
			$timeout(() => {
				const subjectControl = document.getElementById('subjectInputCtrl');
				if (subjectControl)
					subjectControl.addEventListener('keydown', onSubjectKeydown);

				document.getElementById('composeEditor').addEventListener("keydown", function (event) {
					try {
						//CTRL+A
						if ((event.ctrlKey || event.metaKey) && event.key === "a") {

							var sigField = document.getElementById('divSignature');
							if (sigField) {
								//get range between start and signature
								const selRange = document.createRange();
								var childArr = Array.from(document.activeElement.children);
								var signatureElem = childArr.filter(f => f.id == "divSignature")[0];

								//prevent selection
								event.preventDefault();

								selRange.setStart(childArr[0], 0);
								selRange.setEnd(signatureElem, 0);

								//Apply range selection
								const selection = window.getSelection();
								selection.removeAllRanges();
								selection.addRange(selRange);
							}
						}
					} catch (err) {
						console.error("Failure selecting compose body.", err);
					}
				});

			});
		}

		function onEverythingReady() {
			// 1 = reply, 2=replyall, 3=forward, undefined=new mail
			if (popoutPacket && (popoutPacket.reply === 1 || popoutPacket.reply === 2))
				$timeout(focusEditor, 250);
			else
				$timeout(focusToField, 20);

			// Now that everyting is ready... we can start the watches for changes

			$scope.$watch('$ctrl.reply.subject', onReplySubjectChanged);
			$scope.$watch('$ctrl.selectedFromAddress', onSelectedFromAddressChanged);
			$scope.$watch('$ctrl.selectedSignature', onSelectedSignatureChanged);
			$scope.$watch('$ctrl.message.attachments', onMessageAttachmentsChanged);
			$scope.$watch("$ctrl.messageForm.subject.$valid", () =>  {
				$timeout(throttledOnResize);
			})
		}

		function onSubjectKeydown(e) {
			try {
				if (e.key === "Tab" && !e.shiftKey) {
					focusEditor();
					e.preventDefault();
				}
			} catch (err) {
				// Ignore any exceptions and just use typical tab behavior
			}
		}

		async function processMessage(loadedMessage) {
			vm.messageDoesNotExist = !loadedMessage;
			if (vm.messageDoesNotExist) {
				vm.message = {};
				return;
			}

			vm.message = loadedMessage;

			// Clean existing signature images.
			if (vm.message.messageHTML && vm.message.attachments && vm.message.attachments.length) {
				const jHtml = $(`<div>${vm.message.messageHTML}</div>`);
				const sigDiv = jHtml.find("#divSignature");
				if (sigDiv && sigDiv.length) {
					const imgs = sigDiv.find("img[src]");
					for (let i = 0; i < imgs.length; i++) {
						const src = imgs[0].getAttribute("src");
						if ((!src.contains("attachedfile?data=") && !src.contains("attachment/inline?data=")) || !src.contains("&cid="))
							continue;

						const srcData = src.substring(src.indexOf("?data="), src.indexOf("&cid="));
						for (let j = 0; j < vm.message.attachments.length;) {
							if (vm.message.attachments[j].link.contains(srcData))
								vm.message.attachments.splice(j, 1);
							else
								j++;
						}
					}

					vm.message.messageHTML = jHtml.html();
				}
			}

			//vm.setupCategories();
			vm.isSentItems = vm.message.folder && (vm.message.folder.toLowerCase().indexOf('sent items') !== -1);

			if (vm.message.fromAddress.email) {
				var fromAddr = coreDataContacts.getContactByEmail(vm.message.fromAddress.email) || {};
				vm.message.fromAddress.displayAs = fromAddr.displayAs;
			}
		}

		function processFolderList(response) {
			vm.isInSharedFolder = false;
			for (let i = 0; i < response.data.count; ++i) {
				const folder = findFolderRecursive(response.data.folderList[i]);
				if (!folder)
					continue;
				vm.isInSharedFolder = folder.access && folder.access != 0;
				vm.folderAccess = folder.access;
				break;
			}

			function findFolderRecursive(folderObj) {
				if (folderObj.ownerEmailAddress.toLowerCase() === popoutPacket.owner.toLowerCase() &&
					folderObj.path.toLowerCase() === popoutPacket.folder.toLowerCase()) {
					return folderObj;
				}

				if (folderObj.subFolders) {
					for (let i = 0; i < folderObj.subFolders.length; ++i) {
						const found = findFolderRecursive(folderObj.subFolders[i]);
						if (found)
							return found;
					}
				}

				return false;
			}
		}
		async function loadComposeData() {
			await ensureEditorIsSet();
			if (!vm.message || !vm.message.uid) {

				await setSignatureOnNewMessage();
				setEditorContentForReply(vm.editorScope, true);
			}
			else {
				setEditorContentForReply(vm.editorScope);

			}
			async function setSignatureOnNewMessage() {
				let hasAnySignatures = false;
				if (vm.fromAddresses) {
					vm.fromAddresses.forEach((v) => {
						if (!v.signatures)
							return;
						v.signatures.forEach((s) => {
							if (s.guid && s.guid != 'NONE')
								hasAnySignatures = true;
						});
					});
				}
				if (hasAnySignatures) {
					var newHtml = "<br/><br/>" + getSignatureDivHtml();
					try {

						await vm.editorScope.setHtmlPromise(newHtml);
					} catch (err) {
						console.log("[Error Setting Html]:setSignatureOnNewMessage", { err });
					}
				}
			}

		}
		async function setupComposeData() {
			if (!vm.message || !vm.message.uid)
				setupComposeForNewMessage();
			else {
				const mode = popoutPacket.reply;

				if (vm.message && vm.message.isDraft) {
					// Edit Draft
					setupComposeForDraft();

				} else if (mode !== 3) {
					// Reply
					setupComposeForReply(mode);

				} else {
					// Forward
					setupComposeForForward();
				}

			}
			if (vm.reply.to) {
				vm.recipientListTo = populateRecipientField(vm.reply.to);
			}

			if (vm.reply.cc) {
				vm.recipientListCc = populateRecipientField(vm.reply.cc);

			}

			if (vm.reply.bcc) {
				vm.recipientListBcc = populateRecipientField(vm.reply.bcc);

			}


			vm.reply = vm.reply || {};
			vm.showCCs = vm.reply.cc || vm.reply.bcc;
			vm.reply.owner = vm.reply.owner || (vm.message && vm.message.ownerEmailAddress) || popoutPacket.owner;
			vm.reply.folder = vm.reply.folder || (vm.message && vm.message.folder) || popoutPacket.folder;
			vm.reply.uid = vm.reply.uid || (vm.message && vm.message.uid);

			// If we're attaching one or more forwards through an attachment, clear out the source uid
			if (popoutPacket.attachEmails && popoutPacket.attachEmails.length > 0)
				vm.reply.uid = undefined;

			if (popoutPacket.sid)
				await setupComposeFromSessionId();

			let saveParams = {
				'to': vm.reply.to,
				'cc': vm.reply.cc,
				'bcc': vm.reply.bcc,
				'replyUid': vm.reply.replyUid,
				'replyOwner': vm.reply.replyOwner,
				'replyFromFolder': vm.reply.replyFromFolder,
				'ownerEmailAddress': coreData.user.emailAddress,
				'folder': "drafts",
				'date': Date.now(),
				'from': userDataService.user.emailAddress,
				'replyTo': userDataService.user.emailAddress,
				'subject': vm.reply.subject,
				'priority': vm.reply.priority,
				'readReceiptRequested': vm.reply.requestReadReceipt,
				'deliveryReceiptRequested': vm.reply.requestDeliveryReceipt,
				'markForFollowup': vm.reply.markForFollowup,
				'selectedFrom': vm.selectedFromAddress.source + ':' + vm.selectedFromAddress.email,
				'originalCidLinks': vm.message && vm.message.originalCidLinks,
			};

			if (popoutPacket.attachEmails && popoutPacket.attachEmails.length)
				saveParams.attachedMessages = popoutPacket.attachEmails;

			if (vm.message && vm.message.isDraft && vm.message.uid) {
				// if this draft is in a shared folder AND we do NOT have full access; we need to take ownership by treating
				// this as a "forward of this draft" which will keep attachments but save in this users draft folder.
				if (vm.isInSharedFolder && !vm.folderAccess) {
					saveParams.replyUid = vm.message.uid;
					saveParams.replyOwner = vm.message.ownerEmailAddress;
					saveParams.replyFolder = vm.message.folder;
					vm.message.ownerEmailAddress = coreData.user.emailAddress;
					vm.message.uid = undefined;
					vm.message.folder = "drafts";
					vm.replyForwardMode = MODE.Forward;
				}
				saveParams.draftUid = vm.message.uid;
				saveParams.folder = vm.message.folder;
				saveParams.ownerEmailAddress = vm.message.ownerEmailAddress;
			}

			saveDraftParams.saveParam = 'messageHTML';
			saveDraftParams.saveParams = saveParams;
		}

		async function setupComposeFromSessionId() {
			// Used when we're exporting a list of vcards
			const sessionId = popoutPacket.sid;
			var passedInfo = sessionStorage.getItem(sessionId);
			var data = JSON.parse(passedInfo || "");
			vm.reply.to = (data.emails || []).join("; ");

			try {
				var contactsToGet = [];
				angular.forEach((data.vcards || []), function (contact) {
					// Note that this call, if called with a GAL ID, does not return the same ID as the one you passed in
					contactsToGet.push({ id: contact.id, sourceId: contact.sourceId, sourceOwner: contact.sourceFolder });
				});

				var contactsRes = await $http.post(`~/api/v1/contacts/fetch-many`, contactsToGet);
				var contactInfo = [];
				for (var i = 0; i < contactsRes.data.contacts.length; ++i) {
					// We need to force the id here, since the /contacts/contact call returns the ID of the user, not the ID of the gal profile
					let newContact = contactsRes.data.contacts[i];
					newContact.id = data.vcards[i].id;
					contactInfo.push(newContact);
				}

				var params = JSON.stringify({ contacts: contactInfo });
				var httpPath = "~/api/v1/contacts/export/vcard/data";
				const vcardResult = await $http.post(httpPath, params);
				var guid = vm.attachmentGuid;
				let vcardResultData = vcardResult.data.data;
				var file = dataURLtoBlob(vcardResultData);
				var fileName = vcardResult.data.name;
				var extension = (fileName || '').split('.').pop();
				var type = fileInfoProvider.iconFromExtension(extension).split('-').pop();

				var fd = new FormData();
				fd.append("guid", guid);
				fd.append("file", file, fileName);
				await $http.post("/attachment-put", fd, {
					headers: {
						"Authorization": 'Bearer ' + coreData.getToken(),
						"Content-Type": undefined
					},
					transformRequest: angular.identity
				});

				vm.message.attachments = vm.message.attachments || [];
				vm.message.attachments.push({ filename: fileName, size: file.size, type: type });

				$scope.$applyAsync(); // To make sure onMessageAttachmentsChanged gets fired

				$timeout(function () {
					var attachAreas = $('.forwardAttachments');
					attachAreas.scrollTop(attachAreas.prop("scrollHeight"));
				});

				$timeout(throttledOnResize, 10);

			} catch (err) {
				console.error(err);
			}
		}

		function setupComposeForNewMessage() {
			vm.replyForwardMode = null;
			vm.isNewMessage = true;
			vm.reply = {
				// owner, folder, and uid are undefined
				to: '',
				cc: '',
				bcc: '',
				subject: '',
				priority: 1,
				requestReadReceipt: coreData.user.settings.userMailSettings.requestReadReceipts,
				requestDeliveryReceipt: coreData.user.settings.userMailSettings.requestDeliveryReceipts,
				markForFollowup: false,
			};

			if (popoutPacket.to) {
				popoutPacket.to.formatted = "\"" + popoutPacket.to.displayAs + "\" <" + popoutPacket.to.emailAddress + ">";
				vm.recipientListTo.push({
					displayAs: popoutPacket.to.displayAs,
					emailAddress: popoutPacket.to.emailAddress,
					formatted: popoutPacket.to.formatted,
					type: 'new'
				});
			}
			if (vm.reply.to) {
				vm.recipientListTo = populateRecipientField(vm.reply.to);
			}

			if (vm.reply.cc) {
				vm.recipientListCc = populateRecipientField(vm.reply.cc);

			}

			if (vm.reply.bcc) {
				vm.recipientListBcc = populateRecipientField(vm.reply.bcc);

			}
		}

		function setupComposeForDraft() {
			vm.reply = {
				replyUid: vm.message.uid,
				replyOwner: vm.message.ownerEmailAddress,
				replyFromFolder: vm.message.folder,
				to: vm.message.to,
				cc: vm.message.cc,
				bcc: vm.message.bcc,
				subject: vm.message.subject || "",
				priority: vm.message.importance === 0
					? 1
					: vm.message.importance === 1
						? 0
						: vm.message.importance === -1
							? 2 : 1,
				requestReadReceipt: vm.message.requestingReadReceipt,
				requestDeliveryReceipt: vm.message.requestingDeliveryReceipt,
				markForFollowup: vm.message.flagInfo && !vm.message.flagInfo.isComplete,
			};

			if (vm.message.replyInfo) {
				var replyInfo = vm.message.replyInfo.split(";|");
				if (replyInfo.length === 4) {
					vm.reply.uid = replyInfo[0];
					vm.reply.folder = replyInfo[1] ? replyInfo[1] : vm.message.folder;
					vm.reply.owner = replyInfo[2];
					if (replyInfo[3] == "reply") {
						vm.replyForwardMode = MODE.Reply;
					} else if (replyInfo[3] == "forward") {
						vm.replyForwardMode = MODE.Forward;
					}
				}
			}
		}

		function setupComposeForForward() {
			const translatedForwardAbbreviation = $translate.instant("FORWARD_ABBR");

			vm.replyForwardMode = MODE.Forward;
			vm.reply = {
				replyUid: vm.message.uid,
				replyOwner: vm.message.ownerEmailAddress,
				replyFromFolder: vm.message.folder,
				requestReadReceipt: coreData.user.settings.userMailSettings.requestReadReceipts,
				requestDeliveryReceipt: false,
				subject: subjectWithPrefix(vm.message.subject, translatedForwardAbbreviation)
			};

			if (!popoutPacket.attachEmails || !popoutPacket.attachEmails.length)
				return;

			vm.message.attachments = [];
			for (var i = 0; i < popoutPacket.attachEmails.length; ++i) {
				var v = popoutPacket.attachEmails[i];
				vm.message.attachments.push({ emailUid: v.uid, isAttachedMessage: true, size: v.size, filename: (v.subject + ".eml"), type: "email" });
			}

			if (popoutPacket.attachEmails.length > 1) {
				vm.reply.subject = `${translatedForwardAbbreviation}: `;
			}

			$scope.$applyAsync(); // To make sure onMessageAttachmentsChanged gets fired

			function subjectWithPrefix(subject, fwdText) {
				subject = (subject || '').trim();
				const cleanSubject = subject.toLowerCase();
				const containsForwardIndicator = cleanSubject.substring(0, 4) === "fwd:"
					|| cleanSubject.substring(0, 3) === "fw:"
					|| cleanSubject.substring(0, fwdText.length + 1) === fwdText.toLowerCase() + ':';
				return containsForwardIndicator
					? subject
					: `${translatedForwardAbbreviation}: ${subject}`;
			}
		}

		function setupComposeForReply(mode) {
			vm.reply = {
				replyUid: vm.message.uid,
				replyOwner: vm.message.ownerEmailAddress,
				replyFromFolder: vm.message.folder,
				cc: '',
				bcc: '',
				requestReadReceipt: coreData.user.settings.userMailSettings.requestReadReceipts,
				requestDeliveryReceipt: false,
			};

			if (mode == 2 && vm.message.to != undefined) {
				var parsedToAddresses = "";
				var toAddresses = [];

				let tempEmailWhole = "";
				let tempRawEmail = "";
				if (vm.message.replyTo != undefined) {
					tempEmailWhole = parseEmailAndName(vm.message.replyTo);
					tempRawEmail = parseEmail(vm.message.replyTo);
				} else {
					tempEmailWhole = parseEmailAndName(vm.message.from);
					tempRawEmail = parseEmail(vm.message.from);
				}

				let tempToAddresses = SmartAddressParsing(vm.message.to);
				for (var i = 0; i < tempToAddresses.length; i++) {
					let to = parseEmailAndName(tempToAddresses[i]);
					let rawEmail = parseEmail(to);
					if (emailValidationService.isValidEmail(rawEmail) && rawEmail != tempRawEmail) {
						toAddresses.push(to);
					}
				}

				if (toAddresses.length > 0) {
					parsedToAddresses = toAddresses.join("; ");
					vm.reply.to = tempEmailWhole + "; " + parsedToAddresses;
				} else {
					vm.reply.to = tempEmailWhole;
				}
			} else if (mode === 1 && (popoutPacket.folder && popoutPacket.folder.toLowerCase() === 'sent items') && vm.message.to != undefined) {
				let tempEmailWhole = "";
				let tempRawEmail = "";
				vm.reply.markForFollowup = vm.message.flagInfo && !vm.message.flagInfo.isComplete;
				if (vm.message.replyTo != undefined) {
					tempEmailWhole = parseEmailAndName(vm.message.replyTo);
					tempRawEmail = parseEmail(vm.message.replyTo);
				} else {
					tempEmailWhole = parseEmailAndName(vm.message.from);
					tempRawEmail = parseEmail(vm.message.from);
				}
				let tempToAddresses = SmartAddressParsing(vm.message.to);
				if (tempToAddresses.length > 0) {
					let to = parseEmailAndName(tempToAddresses[0]);
					let rawEmail = parseEmail(to);
					if (emailValidationService.isValidEmail(rawEmail) && rawEmail != tempRawEmail) {
						vm.reply.to = to;
					} else {
						vm.reply.to = tempEmailWhole;
					}
				} else {
					vm.reply.to = tempEmailWhole;

				}
			} else {
				// Normal replies
				vm.reply.to = parseEmailAndName(vm.message.replyTo || vm.message.from);
			}

			if (mode == 2 && vm.message.cc != undefined) {
				var tempCcAddresses = SmartAddressParsing(vm.message.cc);
				var ccAddresses = $.map(tempCcAddresses, function (address) { return parseEmailAndName(address.trim()); });
				ccAddresses = $.grep(ccAddresses, function (address) {
					var addParse = parseEmailAndName(address.trim());
					return emailValidationService.isValidEmail(parseEmail(addParse)) && addParse != userDataService.user.emailAddress;
				});
				vm.reply.cc = ccAddresses.join(";");
			}

			//vm.reply.cc = mode == 2 && vm.message.cc != undefined ? vm.message.cc : '';
			vm.reply.subject = vm.message.subject != undefined ? vm.message.subject : "";
			const translatedReplyAbbreviation = $translate.instant("REPLY_ABBR");
			if (vm.reply.subject.trim().substring(0, 3).toLowerCase() !== "re:" &&
				vm.reply.subject.trim().substring(0, translatedReplyAbbreviation.length + 1).toLowerCase() !== translatedReplyAbbreviation.toLowerCase() + ":") {
				vm.reply.subject = translatedReplyAbbreviation + ": " + vm.reply.subject;
			}
			vm.message.attachments.length = 0;

			vm.replyForwardMode = MODE.Reply;
			$scope.$applyAsync(); // To make sure onMessageAttachmentsChanged gets fired
		}
		const ensureEditorIsSet = function () {
			if (isComposeInitialized && vm.editorScope && vm.editorScope.editor) {
				if (!isEditorFullyInitialized)
					froalaTryInitialize(vm.editorScope.editor);
				return $q.resolve(vm.editorScope.editor)
			}
			const defer = $q.defer();

			vm.editorScope.initEditor();
			vm.notifyOnEditorInitialized = function (editor) {
				vm.notifyOnEditorInitialized = undefined;
				defer.resolve(editor);
			}
			return defer.promise;
		}


		function SmartAddressParsing(emailAddresses) {
			var result = [];
			try {
				var inQuotes = false;
				var currentEmail = "";
				for (var index = 0; index <= emailAddresses.length; index++) {
					if (index == emailAddresses.length) {
						result.push(currentEmail.trim());
						currentEmail = "";
					}
					else {
						var currentChar = emailAddresses[index];
						if (currentChar == "\"") {
							if (inQuotes)
								inQuotes = false;
							else
								inQuotes = true;
						}

						if (currentChar == "," && inQuotes == false) {
							result.push(currentEmail.trim());
							currentEmail = "";
						} else {
							currentEmail += currentChar;
						}
					}
				}
			} catch (e) {
				result = emailAddresses.split(",");
			}
			return result;
		}

		function dataURLtoBlob(dataurl) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		}

		const populateRecipientField = function (recipientField) {
			const recipientArray = [];
			$.each(recipientField.split(regexSplit), function (i, v) {
				v = v.trim();
				if (v) {
					let found = false;

					if (v.indexOf("@") === -1 && v.indexOf("|") !== -1) {
						const split = v.split("|");
						if (split.length === 3) {
							for (let j = 0; j < recipientArray.length; ++j) {
								if (recipientArray.formatted.trim() === split[i])
									found = true;
							}
							if (!found)
								recipientArray.push({
									formatted: split[0],
									displayAs: split[0],
									source: "CONTACT_GROUP",
									id: split[1],
									sourceAccount: split[2]
								});
						}
					} else {
						const address = stripAddress(v);
						const display = stripFriendlyName(v);

						for (let j = 0; j < recipientArray.length; ++j) {
							if (recipientArray[j].formatted.trim() === v || recipientArray[j].emailAddress.trim() === address)
								found = true;
						}
						if (!found)
							recipientArray.push(
								{
									formatted: v,
									displayAs: display,
									emailAddress: address,
									type: "new"
								});
					}
				}
			});
			return recipientArray;
		}

		function setEditorContentForReply(editor, skipHtml) {
			if (!skipHtml) {
				var content = "";
				if (popoutPacket.content) {
					content = popoutPacket.content;
				} else if (popoutPacket.attachEmails && popoutPacket.attachEmails.length > 0) { 
					content = "<div></div><br/>" + getSignatureDivHtml(); // use divs on first line so we can easily set focus to it.
					setEditorHtml(content);
				} else if (!vm.message || !vm.message.isDraft) {
					content = "<div></div><br/>" + getSignatureDivHtml() + getPreviousMessageForReply(); // use divs on first line so we can easily set focus to it.
					setEditorHtml(content);
				} else {
					// Load the draft content
					content = "" + (vm.message.messageHTML != undefined ? vm.message.messageHTML : vm.message.messagePlainText);
					var contentHtml = $(`<div>${content}</div>`);
					var sigDiv = contentHtml.find("#divSignature");
					if (sigDiv && sigDiv.length) {
						vm.draftSignatureKey = sigDiv.attr("data-signature-key");
						if (vm.draftSignatureKey) {
							$.each(vm.signatures, function (j, s) {
								if (s.guid === vm.draftSignatureKey) {
									vm.selectedSignature = s;
								}
							});
						}

					} else if (vm.signatures && vm.signatures.length > 0) {
						// If the divSignature is missing we need to add it; it will be set with the default 
						//	 this is exception case for when a draft is started without signatures but edited with signatures added to account
						var htmlContent = $(`<div>${content}</div>`);
						var prevMessageHr = htmlContent.find("#previousmessagehr");
						if (prevMessageHr.length) {
							$(prevMessageHr[0]).before("<div></div><br/>" + getSignatureDivHtml() + "<br/>");
							content = htmlContent.html();
						} else {
							content = content + "<div></div><br/>" + getSignatureDivHtml();
						}
					}
					setEditorHtml(content);
				}
			}
			// Set auto-complete values
			if (vm.reply.to) {
				vm.recipientListTo = populateRecipientField(vm.reply.to);
			}

			if (vm.reply.cc) {
				vm.recipientListCc = populateRecipientField(vm.reply.cc);

			}

			if (vm.reply.bcc) {
				vm.recipientListBcc = populateRecipientField(vm.reply.bcc);

			}

			$scope.$applyAsync(); // To make sure onSignatureChanged gets fired
			$timeout(throttledOnResize);
			$timeout(function () { setFroalaOnContentChanged(); }, 50);

			function setFroalaOnContentChanged() {

				vm.editorScope.editor.events.on("contentChanged",
					function () {
						if (vm.messageForm && !vm.messageForm.$dirty)
							vm.messageForm.$setDirty();
						tryDoThrottledAutoSave();
					});
			}

			async function setEditorHtml(content) {
				try {
					// Check for a wrapper div. If it is present, remove it to prevent issues with paragraph styling.
					var $content = $(content);
					if ($content.length === 1 && $content[0].nodeName.toUpperCase() === "DIV" && $content.children("div,h1,h2,h3,h4,pre,blockquote,ul,ol,p").length > 0)
						await editor.setHtmlPromise($content.html());
					else {
						await editor.setHtmlPromise(content);
					}

				} catch (err) {
					console.log("[Error Setting Html]:setEditorContentForReply", { err });
				}
			}
		}

		function windowOnBeforeUnload(event) {
			if (!skipAskCheck && vm.hasMessageChanged()) {
				skipAskCheck = true;
				return true;
			}
		}

		function closeWindow() {
			window.close();
		}

		function hasMessageChanged() {
			return !vm.hasSentMessage && vm.messageForm && vm.messageForm.$dirty;
		}

		function onSend() {
			throttledDoSendMessage();
		}

		async function scopeCancel(ev) {
			if (!vm.hasMessageChanged()) {
				skipAskCheck = true;
				window.close();
				return;
			}

			if (vm.overSizeLimit) {
				//Message too big error
				var overSizeText = $translate.instant('DOMAIN_SIZE_LIMIT_EXCEEDED_SHOW_LIMIT', { size: userDataService.user.settings.diskSpace.maxAllowedMessageSize });
				let confirm = $mdDialog.confirm()
					.title($translate.instant('DRAFT'))
					.textContent($translate.instant('MAIL_CONFIRM_SAVE_DRAFT_OVERSIZE', { text: overSizeText }))
					.targetEvent(ev)
					.ok($translate.instant('YES'))
					.cancel($translate.instant('NO'));
				try {
					await $mdDialog.show(confirm);
					skipAskCheck = true;
					window.close();
				} catch (err) {
					// Exception means they said no on the dialog
				}
			} else {
				let confirm = $mdDialog.confirm()
					.title($translate.instant('DRAFT'))
					.textContent($translate.instant('MAIL_CONFIRM_SAVE_DRAFT'))
					.targetEvent(ev)
					.ok($translate.instant('YES'))
					.cancel($translate.instant('NO'));
				try {
					await $mdDialog.show(confirm);
					doDraftSave();
					skipAskCheck = true;
					waitForDraftSaveThenCloseWindow();
				} catch (err) {
					// Exception means they said no on the dialog
					skipAskCheck = true;
					window.close();
				}
			}
		}

		function onCancel(event) {
			window.close();
		}

		function onSaveDraft() {
			if (vm.filesUploading > 0) {
				errorHandling.report($translate.instant("FILE_UPLOADING_WAIT"));
				return false;
			}
			if (vm.savingDraft) {
				errorHandling.report($translate.instant("DRAFT_SAVING_WAIT"));
				return false;
			}
			doDraftSave();
		}

		function onSetPriority(priority) {
			vm.reply.priority = priority;
			tryDoThrottledAutoSave();
		}

		function onToggleRequestReadReceipt() {
			vm.reply.requestReadReceipt = !vm.reply.requestReadReceipt;
			tryDoThrottledAutoSave();
		}

		function onToggleRequestDeliveryReceipt() {
			vm.reply.requestDeliveryReceipt = !vm.reply.requestDeliveryReceipt;
			tryDoThrottledAutoSave();
		}

		function onToggleMarkFollowup() {
			vm.reply.markForFollowup = !vm.reply.markForFollowup;
			tryDoThrottledAutoSave();
		}

		async function onLinkFile() {
			var promises = [coreDataSettings.settingsData.userConnectedServices];

			if (vm.fileStorageEnabled)
				promises.push(coreDataFileStorage.init());

			try {
				const success = await Promise.all(promises);

				var services = [];
				var fileStorageList = [];

				if (vm.fileStorageEnabled) {
					fileStorageList = coreDataFileStorage.getFolders();
					services = [{ type: -1, friendlyName: $translate.instant('SMARTERMAIL_FILE_STORAGE') }];
				}

				if (vm.cloudStorageEnabled) {
					services = services.concat(success[0]);
				}

				let confirm;
				try {
					confirm = await $mdDialog.show({
						locals: {
							services: services,
							fileStorageList: fileStorageList
						},
						controller: "linkFileController",
						controllerAs: "ctrl",
						templateUrl: "app/email/modals/link-file.dlg.html",
						clickOutsideToClose: false
					});
				} catch (err) {
					// ignore errors here, since it's just the user cancelling the modal
					return;
				}

				if (confirm.type === -1) {
					insertLinkToHtml(confirm.link, confirm.fileName);
				} else {
					try {
						var params = { folder: confirm.folder, id: confirm.id };
						const success = await $http.post('~/api/v1/mail/filestorage-link/' + confirm.typeString, params);
						insertLinkToHtml(success.data.publicLink, confirm.fileName);
					} catch (err) {
						errorHandling.report(err.data ? err.data.message : err);
					}
				}

			} catch (err) {
				errorMessageService.showErrorMessage(err);
			}

			function insertLinkToHtml(link, filename) {
				var e = vm.editorScope;
				if (!e || typeof e.insertHtml !== 'function')
					return;
				e.insertFileLink(link, filename)

			}
		}

		async function openRecipientsModal(type, ev) {
			try {
				const success = await Promise.all([coreDataCategories.init(), $http.get("~/api/v1/contacts/sources")]);

				const dialogSuccess = await $mdDialog.show({
					locals: {
						sources: success[1].data.sharedLists.concat($.map(coreDataContacts.categories(),
							function (cat) { return { itemID: 'category', displayName: cat.name }; }
						)),
						type: "RECIPIENTS"
					},
					controller: 'emailRecipientsController',
					controllerAs: 'ctrl',
					templateUrl: 'app/shared/modals/recipients.dlg.html',
					targetEvent: ev,
					clickOutsideToClose: false
				});

				var recipientList = "recipientList" + type;
				type = type.toLowerCase();

				$.each(dialogSuccess.selectedContacts,
					function (i, v) {
						let found = false;

						if (v.isGroup) {
							for (let j = 0; j < vm[recipientList].length; ++j) {
								if (vm[recipientList][j].source === "CONTACT_GROUP" && vm[recipientList][j].id === v.id) {
									found = true;
								}
							}
							if (!found)
								vm[recipientList].push({
									displayAs: v.displayAs,
									formatted: v.displayAs,
									id: v.id,
									source: "CONTACT_GROUP",
									sourceAccount: v.sourceAccount
								});
						} else {
							const recipient = `"${v.displayAs}" <${v.emailAddressList[0]}>`;
							// TODO: Should be able to do a grep here instead
							for (let j = 0; j < vm[recipientList].length; ++j) {
								if (vm[recipientList][j].formatted === v) {
									found = true;
								}
							}
							if (!found)
								vm[recipientList].push({
									formatted: recipient,
									displayAs: v.displayAs,
									emailAddress: v.emailAddressList[0],
									type: 'new'
								});
						}
					});

				vm.reply[type] = "";
				$.each(vm[recipientList],
					function (i, v) {
						vm.reply[type] += v.source === "CONTACT_GROUP"
							? `${v.displayAs}|${v.id}|${v.sourceAccount};`
							: v.formatted + ";";
					});

				$scope.$applyAsync();
			} catch (err) {
				// Failures just mean they closed the modal
			}
		}

		function showCCFields() {
			vm.showCCs = !vm.showCCs;
			$timeout(() => throttledOnResize());
		}

		function onResize() {
			if (!vm.loaded)
				return;

			const actionBarMargin = 0;
			const actionBar = document.querySelector('action-bar');
			const actionBarHeight = actionBar && actionBar.getBoundingClientRect().height;

			const replyHeaderMargin = 0;
			const replyHeader = document.querySelector('#replyHeader');
			const replyHeaderHeight = replyHeader && replyHeader.getBoundingClientRect().height;

			let attachmentsHeight = 0;
			if (vm.message && vm.message.attachments && vm.message.attachments.length) {
				const emailAttachments = document.querySelector('.emailAttachments');
				attachmentsHeight = emailAttachments && emailAttachments.getBoundingClientRect().height;
			}

			let calcHeight = $(window).height()
				- actionBarHeight - actionBarMargin
				- replyHeaderHeight - replyHeaderMargin
				- attachmentsHeight
				- 35;

			// If this gets changed, make sure to update the minHeight and height attributes on the st-html-editor HTML element
			if (calcHeight < vm.minHeight)
				calcHeight = vm.minHeight;

			const froalaToolbar = document.querySelector('.fr-toolbar');
			const froalaToolbarHeight = froalaToolbar && froalaToolbar.getBoundingClientRect().height;
			calcHeight = calcHeight - froalaToolbarHeight;

			// Do not set heights on these elements or the image resize border will break
			$('#composeEditor .fr-wrapper').css({ 'max-height': calcHeight, 'min-height': calcHeight });
			$('#composeEditor .fr-element').css({ 'min-height': calcHeight });
			$('#composeEditor').css({ 'visibility': 'visible' });
			setTimeout(() => $('.emailPopout').scrollTop(0), 100);
		}

		// #region FROALA EDITOR --------------------------------------------------------------------------------------

		function froalaOnInit(editor) {
			// Since we don't use the second toolbar, we should just focus the editor on click
			$('.fr-second-toolbar').click(() => editor.events.focus(true));
			isComposeInitialized = true;
			froalaTryInitialize(editor);
			if (typeof vm.notifyOnEditorInitialized === "function") {
				vm.notifyOnEditorInitialized(editor);
			}

			spinner.hide();
		}

		function focusToField() {
			let to = $('.area-to').find('input')[0];
			$(to).focus();
			$(to).trigger("focus");
			$('#sendToField').focus();
			$('#sendToField').trigger("focus");
		}

		function focusEditor() {
			if (vm.editorScope) {
				vm.editorScope.focusEditor();

			} else {
				// this shouldn't be needed but leaving JIC
				const frElement = $("st-html-editor textarea.froalaEditor");
				const frEditor = frElement && frElement.length && frElement[0]["data-froala.editor"];
				if (frEditor && frEditor.$el && typeof frEditor.$el.focus === 'function')
					frEditor.$el.focus();
			}
		}

		function froalaTryInitialize(frEditor) {
			// We call this function from both froalaOnInit AND activate, since the order of initialization isn't consistent
			// The check below is to make sure that froala told us it was ready.
			if (!isComposeInitialized || !isCoreDataLoaded)
				return;

			//this function is used to remove the editors drag and drop, 
			//focus the editor/to field,
			//and to get the original html content of the message once it has loaded.

			// Froala attaches an anonymous event handler to the editor for handling drop events.
			// Need to loop through the events so we can find and remove the anonymous handler.
			var frView = frEditor.$el;
			frView.off("drop");

			var editorEvents = frView.data("events");
			for (var i = 0; i < editorEvents.length; i++) {
				if (!editorEvents[i][0].toUpperCase().startsWith("DROP"))
					continue;
				frView[0].removeEventListener("drop", editorEvents[i][1]);
			}

			var fontF = coreData.user.settings.userMailSettings.composeFont;
			if (fontF == "lucida")
				fontF = 'lucida sans';

			// frView is a bootstrap object. Turn it into a jQuery object for modifying the CSS.
			$(frView[0])
				.css("font-family", fontF)
				.css("font-size", coreData.user.settings.userMailSettings.composeFontSize);

			$timeout(throttledOnResize);
			isEditorFullyInitialized = true;
		}

		function froalaOnImageInserted(img, response) {
			var parse = JSON.parse(response);
			var link = parse.link;
			var fileName = parse.returnData.fileName ? parse.returnData.fileName : JSON.parse(parse.returnData).fileName;
			insertedInlineAttachments.push({ link: link, fileName: fileName, cid: parse.cid });
			tryDoThrottledAutoSave();
		}

		// #endregion

		// #region ATTACHMENT FUNCTIONS -------------------------------------------------------------------------------

		function uploaderOnInit(pubFuncs) {
			vm.uploaderFuncs = pubFuncs;
		}

		function uploaderOnPreUpload(fileData, file) {
			vm.filesUploading++;

			if (!vm.message.attachments)
				return;

			for (var i = 0; i < vm.message.attachments.length; ++i) {
				var f = vm.message.attachments[i];
				if (f.size == fileData.size && f.filename == fileData.name) {
					vm.filesUploading--;
					file.cancel();
					errorHandling.warn("MAIL_SAME_FILE_UPLOAD");
					return;
				}
			}

			$timeout(function () {
				throttledOnResize();
			}, 1);
		}

		function uploaderOnUploaded(result, dropzone, file) {
			var extension = (result.name || '').split('.').pop();
			var icon = fileInfoProvider.iconFromExtension(extension).split('-').pop();
			if (icon === 'file') {
				//so it doesn't try using toolsicon-file-file
				icon = '';
			}

			var attachment = {
				isNew: true,
				filename: result.name,
				type: icon,
				isAttachedMessage: false,
				size: result.size,
				origFile: file
			};

			if (result.type.startsWith('image/') && result.size < 1000000) {
				attachment.previewImage = stSiteRoot + 'attachment/preview-uploaded?data=' + btoa(file.uniqueIdentifier);
			}

			insertedInlineAttachments = $.grep(insertedInlineAttachments, (att) => att.fileName != file.fileName);

			vm.message.attachments = vm.message.attachments || [];

			var foundFile = -1;
			for (var i = 0; i < vm.message.attachments.length; ++i) {
				var f = vm.message.attachments[i];
				if (f.filename === attachment.filename) {
					foundFile = i;
					break;
				}
			}

			if (foundFile !== -1) {
				attachment.previewImage = vm.message.attachments[i].previewImage;
				vm.message.attachments[i] = attachment;
			} else {
				vm.message.attachments.push(attachment);
			}

			hasNewAttachment = true;
			if (vm.messageForm)
				vm.messageForm.$setDirty();

			vm.filesUploading--;

			onMessageAttachmentsChanged();
			$timeout(function () {
				$(window).trigger('resize');
				var attachAreas = $('.forwardAttachments');
				attachAreas.scrollTop(attachAreas.prop("scrollHeight"));
			});

			$timeout(function () {
				tryDoThrottledAutoSave();
				throttledOnResize();
			}, 1);
		}

		function uploaderOnUploadFailure(file, exception) {
			vm.message.attachments = $.grep(vm.message.attachments || [], (a) => a.filename != file.name);
			onMessageAttachmentsChanged();

			if (exception.message === "EXCEPTION_IO" || exception.message === "EXCEPTION_FILE_NOT_FOUND") {
				exception.message = "MAIL_UPLOAD_FAILED_IO";
			}

			// Yeah I dont know it doesnt like, render right if i dont timeout it in this case?
			$timeout(function () {
				if (exception.message.indexOf('MAX_SIZE_EXCEEDED|') > -1) {
					exception.message = exception.message.replace('MAX_SIZE_EXCEEDED|', '');
					toaster.clear(attachOversizeToast);
					attachOversizeToast = toaster.pop({
						type: 'error',
						title: null,
						body: $translate.instant("MAX_SIZE_EXCEEDED", { size: exception.message }),
						timeout: 10000,
						bodyOutputType: 'trustedHtml'
					});
				} else if (exception.message.indexOf('MALWARE_FOUND|') > -1) {
					exception.message = exception.message.replace('MALWARE_FOUND|', '');
					toaster.pop({
						type: 'error',
						title: null,
						body: $translate.instant("MALWARE_FOUND", { filename: exception.message }),
						timeout: 10000,
						bodyOutputType: 'trustedHtml'
					});
				} else {
					errorHandling.report(exception.message);
				}
			});

			vm.filesUploading--;

			$timeout(function () {
				throttledOnResize();
			}, 1);
		}

		function uploaderOnCanceled(file) {
			vm.filesUploading--;
			uploaderOnFileRemoved(file);
			attachmentCheckSizeLimit();
		}

		function uploaderOnFileRemoved(attachment) {
			try {
				if (!vm.message.attachments)
					return;

				vm.message.attachments = $.grep(vm.message.attachments,
					(att) => (att.filename != attachment.filename || att.size != attachment.size)
				);

				onMessageAttachmentsChanged();
				if (vm.messageForm)
					vm.messageForm.$setDirty();
				tryDoThrottledAutoSave();
			} finally {
				attachmentCheckSizeLimit();
			}

			$timeout(function () {
				throttledOnResize();
			}, 1);
		}

		function attachmentRemoveForwardedFile(attachment, ev) {
			if (attachment.isAttachedMessage) {
				// TODO: Storing data to popoutPacket seems like a bad idea
				popoutPacket.attachEmails = $.grep(popoutPacket.attachEmails, (att) => att.uid !== attachment.emailUid);
				vm.message.attachments = $.grep(vm.message.attachments, (att) => att.emailUid !== attachment.emailUid);
			} else if (!attachment.isNew) {
				if (attachment.partID) {
					vm.reply.removeAttachments = vm.reply.removeAttachments || [];
					vm.reply.removeAttachments.push(attachment.partID);
				}
				saveDraftParams.saveParams.excludeFiles = vm.reply.removeAttachments;
				vm.message.attachments = $.grep(vm.message.attachments, (att) => att.partID != attachment.partID);
			} else {
				if (typeof vm.uploaderFuncs.removeFile === 'function') {
					vm.uploaderFuncs.removeFile(attachment.origFile);
				}

				vm.message.attachments = $.grep(vm.message.attachments,
					(att) => att.filename != attachment.filename || att.size != attachment.size
				);
				
				if (attachment.partID) {
					vm.reply.removeAttachments = vm.reply.removeAttachments || [];
					vm.reply.removeAttachments.push(attachment.partID);
				}
			}

			if (vm.messageForm)
				vm.messageForm.$setDirty();
			onMessageAttachmentsChanged();
			tryDoThrottledAutoSave();

			$timeout(function () {
				throttledOnResize();
			}, 1);
		}

		function attachmentCheckSizeLimit() {
			if (!isComposeInitialized)
				return;

			var maxSize = userDataService.user.settings.diskSpace.maxAllowedMessageSize;
			var currentSize = 0;

			if (vm.message.attachments) {
				vm.message.attachments.forEach(function (a) {
					currentSize += a.size;
				});
			}

			vm.overSizeLimit = currentSize > maxSize;
			if (!vm.overSizeLimit) {
				if (messageOversizeToast)
					toaster.clear(messageOversizeToast);
				return;
			}

			if (!messageOversizeToast || messageOversizeToast.toastId != toaster.toast.toastId) {
				toaster.clear(messageOversizeToast);
				messageOversizeToast = toaster.pop({
					type: 'error',
					title: null,
					body: $translate.instant("DOMAIN_SIZE_LIMIT_EXCEEDED_SHOW_LIMIT", { size: maxSize }),
					timeout: 0,
					bodyOutputType: 'trustedHtml',
					onHideCallback: function () {
						messageOversizeToast = undefined;
					}
				});
			}
		}

		// #endregion

		// #region AUTOCOMPLETE FUNCTIONS -----------------------------------------------------------------------------

		async function autocompleteInit() {
			if (isAutoCompleteLoaded)
				return;
			try {
				vm.contacts = [];
				if (!userDataService.user.isSysAdmin) {
					const success = await $http.get("~/api/v1/settings/auto-complete-list-limit/1000");
					vm.contacts = success.data.emailData;
				}
			} catch (err) {
				if (err != undefined)
					errorHandling.report($translate.instant("ERROR_AUTO_COMPLETE_LIST"));
			} finally {
				isAutoCompleteLoaded = true;
			}
		}

		function getContactStringFromList(list) {
			let result = "";
			list.forEach((v) => {
				result += (v.source && v.source.toUpperCase() === "CONTACT_GROUP")
					? `${v.displayAs}|${v.id}|${v.sourceAccount};`
					: `${v.formatted};`;
			});
			return result;
		}

		function onRecipientListChanged() {
			$timeout(() => {
				vm.reply.to = getContactStringFromList(vm.recipientListTo);
				vm.reply.cc = getContactStringFromList(vm.recipientListCc);
				vm.reply.bcc = getContactStringFromList(vm.recipientListBcc);
				$timeout(() => {
					throttledOnResize();
					tryDoThrottledAutoSave();
				});
			});
		}

		// #endregion

		// #region SEND FUNCTIONS -------------------------------------------------------------------------------------

		async function doSendMessage() {
			if (vm.hasSentMessage)
				return;

			if (vm.filesUploading > 0) {
				errorHandling.report($translate.instant("FILE_UPLOADING_WAIT"));
				return;
			}

			if (vm.savingDraft) {
				errorHandling.report($translate.instant("DRAFT_SAVING_WAIT"));
				return;
			}

			if (vm.recipientListTo.length === 0 && vm.recipientListCc.length === 0 && vm.recipientListBcc.length === 0) {
				errorHandling.report($translate.instant("NO_TO_ADDRESS"));
				return;
			}


			try {
				var editorHtml = vm.editorScope.getHtml();
				// we've seen cases where non-html text is entered while the html content is loading
				//  this was only replicated using firefox through terminal server
				if (editorHtml.indexOf("<") > 0) {
					const nonHtmlContent = editorHtml.substring(0, editorHtml.indexOf("<"));
					if (nonHtmlContent && nonHtmlContent.trim()) {
						editorHtml = `<div>${nonHtmlContent}</div>${editorHtml.substring(editorHtml.indexOf("<"))}`;
					}
				}
				try {
					editorHtml = cleanseHtmlForSending(editorHtml);
				} catch (err) {
					console.log("[Error Sending Message]:cleanseHtmlForSending", { err });
				}

				// Make sure subject has a value
				vm.reply.subject = vm.reply.subject || '';
				if (vm.reply.subject.trim() == "") {
					let confirm = $mdDialog.confirm()
						.title($translate.instant("MISSING_SUBJECT"))
						.textContent($translate.instant("CONFIRMATIONS_MESSAGE_MISSING_SUBJECT"))
						.ok($translate.instant("SEND"))
						.cancel($translate.instant("CANCEL"));
					await $mdDialog.show(confirm);
					// In this case, we WANT $mdDialog.show to throw an exception on CANCEL, so we don't save
				}

				// Make sure we didn't reference attachments and fail to attach anything
				const hasMissingAttachments = isMissingMentionedAttachments(editorHtml);
				if (hasMissingAttachments) {
					let confirm = $mdDialog.confirm()
						.title($translate.instant("MISSING_ATTACHMENT"))
						.textContent($translate.instant("CONFIRMATIONS_MESSAGE_MISSING_ATTACHMENT"))
						.ok($translate.instant("SEND"))
						.cancel($translate.instant("CANCEL"));
					await $mdDialog.show(confirm);
					// In this case, we WANT $mdDialog.show to throw an exception on CANCEL, so we don't save
				}

				await processSendMessage(editorHtml);

			} catch (err) {
				// Catch exception here in case of cancels on modals
				vm.hasSentMessage = false;
				console.log("[Error Sending Message]:", { err });
			}
		}

		function isMissingMentionedAttachments(html) {
			if (hasNewAttachment || (vm.message.attachments && vm.message.attachments.length > 0))
				return false;

			var myRe = new RegExp('([^\w\d_]|^)(' + $translate.instant("ATTACH") + '|' + $translate.instant("ATTACHED") + '|' + $translate.instant("ATTACHMENT") + ')([^\w\d_]|$)', 'i');
			var content = html;

			// Strip off the signature, since we don't want that included
			var signatureIndex = html.search("divSignature");
			if (signatureIndex != -1)
				content = html.substring(0, signatureIndex);

			if (content != null && content.match(myRe) != null) {
				myRe = /([^\w\d_]|^)(<img)([^\w\d_]|$)/i;
				if (content.match(myRe) == null)
					return true;
			}

			return false;
		}

		async function processSendMessage(editorHtml) {
			try {
				vm.hasSentMessage = true;

				var action = {};
				if (vm.replyForwardMode)
					action[vm.replyForwardMode] = true;
				if (vm.reply.markForFollowup)
					action[4] = vm.reply.markForFollowup;

				vm.reply.to = getContactStringFromList(vm.recipientListTo);
				vm.reply.cc = getContactStringFromList(vm.recipientListCc);
				vm.reply.bcc = getContactStringFromList(vm.recipientListBcc);

				var parameters = getMessageSendInputParams(editorHtml);
				await $http.post("~/api/v1/mail/message-put", parameters);

				if (window.opener && typeof window.opener.confirmMessageSent === 'function') {
					window.opener.confirmMessageSent(parameters.replyUid, vm.replyForwardMode);
				}

				vm.ignoreMessageChange = true;
				window.close();
			} catch (failure) {
				vm.hasSentMessage = false;
				if (failure.data && failure.data.message.indexOf("MAIL_FAILED_ATTACHMENT") > -1) {
					var fsp = failure.data.message.split("||");
					errorHandling.report($translate.instant(fsp[0], { file: fsp[1] }));
				} else if (failure.data && failure.data.message.indexOf("DOMAIN_SIZE_LIMIT_EXCEEDED|") > -1) {
					var split = failure.data.message.split("|");
					errorHandling.report($translate.instant('DOMAIN_SIZE_LIMIT_EXCEEDED_SHOW_LIMIT', { size: split[1] }));
				} else {
					errorMessageService.showErrorMessage(failure);
				}
			}
		}

		function getMessageSendInputParams(editorHtml) {
			var action = {};
			if (vm.replyForwardMode)
				action[vm.replyForwardMode] = true;
			if (vm.reply.markForFollowup)
				action[4] = vm.reply.markForFollowup;

			//vm.reply.to = getContactStringFromList(vm.recipientListTo);
			//vm.reply.cc = getContactStringFromList(vm.recipientListCc);
			//vm.reply.bcc = getContactStringFromList(vm.recipientListBcc);

			var inlineToRemove = checkIfInlineStillExists(editorHtml);
			var parameters = saveDraftParams.saveParams || {};

			parameters.to = vm.reply.to;
			parameters.cc = vm.reply.cc;
			parameters.bcc = vm.reply.bcc;
			parameters.date = new Date(Date.now());
			parameters.from = userDataService.user.emailAddress;
			parameters.replyTo = vm.selectedFromAddress.email;
			parameters.subject = vm.reply.subject != undefined ? vm.reply.subject : "";
			parameters.messageHTML = editorHtml;
			parameters.attachmentGuid = vm.attachmentGuid;
			parameters.actions = action;
			parameters.readReceiptRequested = vm.reply.requestReadReceipt;
			parameters.deliveryReceiptRequested = vm.reply.requestDeliveryReceipt;
			parameters.priority = vm.reply.priority;
			parameters.excludeFiles = vm.reply.removeAttachments;
			parameters.inlineToRemove = inlineToRemove;
			parameters.selectedFrom = vm.selectedFromAddress.source + ':' + vm.selectedFromAddress.email;
			parameters.markForFollowup = vm.reply.markForFollowup;

			if ((!popoutPacket.attachEmails || popoutPacket.attachEmails.length <= 1) && vm.message.meetingInfo)
				parameters.originalMeetingInvite = vm.message.meetingInfo.raw;

			if (popoutPacket.attachEmails && !parameters.draftUid)
				parameters.attachedMessages = popoutPacket.attachEmails;

			return parameters;
		}

		function checkIfInlineStillExists(html) {
			var toRemove = [];

			for (var i = 0; i < insertedInlineAttachments.length; ++i) {
				if (html.indexOf(insertedInlineAttachments[i].link) === -1) {
					toRemove.push(insertedInlineAttachments[i].cid);
				}
			}

			for (var cid in vm.message.originalCidLinks) {
				if (html.indexOf(vm.message.originalCidLinks[cid].replace("&", "&amp;").replace("&amp;amp;", "&amp;")) === -1) {
					toRemove.push(cid);
				}
			}

			return toRemove;
		}

		// #endregion

		// #region DRAFT FUNCTIONS ------------------------------------------------------------------------------------

		function autoSave() {
			if (!vm.autoSaveEnabled)
				return;
			if (vm.filesUploading > 0 || vm.savingDraft || vm.overSizeLimit)
				return;
			doDraftSave();
		}

		var throttledAutoSave = _.throttle(autoSave, saveInterval, { leading: false });
		function tryDoThrottledAutoSave() {
			// We need to check these conditions before hitting the throttledAutoSave instead of waiting for the throttle to elapse.
			if (!isComposeInitialized) {
				return;
			}

			throttledAutoSave();
		}

		async function doDraftSave() {
			if (vm.savingDraft)
				return;

			vm.skipDraftCheck = true;

			if (vm.filesUploading > 0)
				return;
			if ($(".fr-element").length === 0)
				return;

			vm.savingDraft = true;

			let draftSaveToast;
			const savingDraftToastTimeout = $timeout(function () {
				draftSaveToast = toaster.pop({
					type: 'info',
					title: null,
					body: $translate.instant("SAVING_DRAFT"),
					timeout: 0,
					bodyOutputType: 'trustedHtml'
				});
			}, 3000);

			var currentHtml = vm.editorScope.getHtml();
			// we've seen cases where non-html text is entered while the html content is loading
			//  this was only replicated using firefox through terminal server
			if (currentHtml.indexOf("<") > 0) {
				const nonHtmlContent = currentHtml.substring(0, currentHtml.indexOf("<"));
				if (nonHtmlContent && nonHtmlContent.trim()) {
					currentHtml = `<div>${nonHtmlContent}</div>${currentHtml.substring(currentHtml.indexOf("<"))}`;
					try {

						await vm.editorScope.setHtmlPromise(currentHtml);
					} catch (err) {
						console.log("[Error Saving Draft]:setStylesEditorHtml", { err });
					} 
				}
			}
			try {
				currentHtml = setStylesEditorHtml(currentHtml);
			} catch (err) {
				console.log("[Error Saving Draft]:setStylesEditorHtml", { err });
			}

			var params = getMessageSendInputParams(currentHtml);
			params.date = new Date(Date.now());
			if (params.excludeFiles)
				params.draftExcludeFiles = JSON.stringify(params.excludeFiles);
			params.draftInlineToRemove = JSON.stringify(checkIfInlineStillExists(currentHtml));
			params.isForward = vm.replyForwardMode === MODE.Forward;
			params.isReply = vm.replyForwardMode === MODE.Reply;

			if (params.originalCidLinks)
				params.originalCidString = JSON.stringify(params.originalCidLinks);

			if (popoutPacket.attachEmails && popoutPacket.attachEmails.length > 0)
				params.draftAttachedMessage = params.draftAttachedMessage =
					JSON.stringify(popoutPacket.attachEmails);

			saveDraftParams.saveParams = params;
			$scope.$applyAsync();

			const saveTimeout = $timeout(() => toaster.clear(draftSaveToast), 5000);

			try {
				const response = await $http.post(`~/api/v1/mail/draft-put/${vm.attachmentGuid}`, params);
				onDraftSaved(response);
			} catch (err) {
				onDraftSaveFailed(err);
			}
			finally {
				vm.savingDraft = false;
				$timeout.cancel(saveTimeout);
				$timeout.cancel(savingDraftToastTimeout);
				toaster.clear(draftSaveToast);
			}
		}

		function onDraftSaved(response) {
			vm.skipDraftCheck = false;

			if (response.data.uid != undefined) {
				saveDraftParams.saveParams.draftUid = parseInt(response.data.uid, 10);
				saveDraftParams.saveParams.originalCidLinks = response.data.originalCidLinks;
				saveDraftParams.saveParams.excludeFiles = vm.reply.removeAttachments = [];

				if (response.data.attachmentInfo && vm.message.attachments && vm.message.attachments.length) {
					const keys = Object.keys(response.data.attachmentInfo);
					for (let i = 0; i < keys.length; ++i) {
						const attInfo = response.data.attachmentInfo[keys[i]];
						for (let j = 0; j < vm.message.attachments.length; ++j) {
							const att = vm.message.attachments[j];
							if (attInfo.actualFileName === att.filename) {
								att.partID = Number(keys[i]);
								att.isAttachedMessage = false;
							}
						}
					}
				}

				if (popoutPacket) {
					popoutPacket.attachEmails = [];
					saveDraftParams.saveParams.draftAttachedMessage = undefined;
				}

				$timeout(() => successHandling.report("DRAFT_SAVED"));

				if (vm.messageForm)
					vm.messageForm.$setPristine();
			}

			$.each(vm.message.attachments, (i, v) => v.isNew = false);
			vm.messageForm.$setPristine();

			$scope.$applyAsync();
		}

		function onDraftSaveFailed(response) {
			vm.skipDraftCheck = false;

			const err = response.data || response;
			if (err && err.message && err.message.indexOf("DOMAIN_SIZE_LIMIT_EXCEEDED|") > -1) {
				const split = err.message.split("|");
				errorHandling.report($translate.instant("DOMAIN_SIZE_LIMIT_EXCEEDED_SHOW_LIMIT", { size: split[1] }));
			}
			else if (err && err.message) {
				errorHandling.report($translate.instant(err.message));
			} else {
				errorHandling.report();
			}
		}

		function waitForDraftSaveThenCloseWindow() {
			if (vm.savingDraft) {
				$timeout(waitForDraftSaveThenCloseWindow, 50);
			} else {
				window.close();
			}
		}

		// #endregion

		// #region WATCH FUNCTIONS ------------------------------------------------------------------------------------

		function onFromAddressChanged(isInitial) {
			vm.selectedSignature = undefined;
			vm.signatures = vm.selectedFromAddress.signatures;

			if (vm.draftSignatureKey) {
				$.each(vm.signatures, function (j, s) {
					if (s.guid === vm.draftSignatureKey) {
						vm.selectedSignature = s;
						vm.draftSignatureKey = null;
					}
				});
			} else {
				$.each(vm.signatures, function (j, s) {
					if (s.isDefault)
						vm.selectedSignature = s;
				});
			}
			if (vm.signatures && vm.signatures.length != 0 && vm.selectedSignature == undefined)
				vm.selectedSignature = vm.signatures[0];
			if (isInitial) return;
			debouncedOnSignatureChanged();
			tryDoThrottledAutoSave();
			throttledOnResize();
		}

		function onSelectedFromAddressChanged(newvalue, oldvalue) {
			if (newvalue && oldvalue != undefined && newvalue != oldvalue) {
				debouncedOnFromAddressChanged();
			}
		}

		function onSelectedSignatureChanged(newvalue, oldvalue) {
			if (newvalue && oldvalue && newvalue != oldvalue) {
				debouncedOnSignatureChanged();
			}
		}

		function onReplySubjectChanged(newSubject, oldSubject) {
			if (newSubject && oldSubject && newSubject !== oldSubject) {
				tryDoThrottledAutoSave();
			}
		}

		async function onSignatureChanged(retry, canDoAutoSave) {

			// If we retry, we don't want to change the save state from the initial call in the chain.
			if (canDoAutoSave === undefined || canDoAutoSave === null) {
				canDoAutoSave = isComposeInitialized;
			}

			if (!vm.editorScope) {
				// Editor Scope Not available
				return;
			}

			if (!vm.editorScope.editor) {
				// Editor not Ready
				await vm.editorScope.getEditor();
				onSignatureChanged(undefined, canDoAutoSave);
				return;
			}

			// We don't want a draft message to set or change the initial 
			if (!isComposeInitialized && vm.message && vm.message.isDraft) {
				return;
			}
			var htmlContent = vm.editorScope.getHtml()
				.split('\"attlinkedfileid:')
				.join(`"${location.origin}${stSiteRoot}attachedfile?data=`);
			var jHtml = $(`<div>${htmlContent}</div>`);
			var sigDiv = jHtml.find("#divSignature");
			var signKey = getSignatureKey();
			if (sigDiv && sigDiv.length) {
				if (signKey)
					sigDiv.attr("data-signature-key", signKey);
				else
					sigDiv.removeAttr("data-signature-key");
				sigDiv.html(getSignatureDivHtmlNoId());
				try {
					await vm.editorScope.setHtmlPromise(jHtml.html());
				} catch (err) {
					console.log("[Error Setting Html]:onSignatureChanged - setHtml", { err });
				}
			} else {
				// try to get the sigDiv the jQuery way if we weren't able to get it from the editor
				// this occurs for replies when default signature set to NONE and user tries to set 
				// a signature.
				sigDiv = $('#divSignature');
				if (sigDiv && sigDiv.length) {
					if (signKey)
						sigDiv.attr("data-signature-key", signKey);
					else
						sigDiv.removeAttr("data-signature-key");
					sigDiv.html(getSignatureDivHtmlNoId());
				} else {
					retry = (retry || 0) + 1;
					if (retry < 10) {
						$timeout(() => onSignatureChanged(retry, canDoAutoSave), 50);
						return;
					}
				}
			}

			if (canDoAutoSave) {
				tryDoThrottledAutoSave();
			}

			function getSignatureKey() {
				if (vm.selectedSignature != undefined && vm.selectedSignature.guid)
					return vm.selectedSignature.guid;
				else
					return null;
			}

			function getSignatureDivHtmlNoId() {
				if (vm.selectedSignature != undefined && vm.selectedSignature.text)
					return vm.selectedSignature.text
						.split('\"attlinkedfileid:')
						.join(`"${location.origin}${stSiteRoot}attachedFile?data=`);
				else
					return "<span> </span>";
			}
		}

		function onMessageAttachmentsChanged(/*newvalue, oldvalue*/) {
			attachmentCheckSizeLimit();
			$scope.$applyAsync();
			$timeout(() => throttledOnResize(), 1);
		}

		// #endregion

		// #region HTML PREPROCESSING ---------------------------------------------------------------------------------

		function cleanseHtmlForSending(html) {
			var jHtml = $(html);
			jHtml = setFontEditorHtml(jHtml);

			// Strip break after from previous message
			jHtml.find("hr#previousmessagehr").css("break-after", "");

			// Strip signature key
			jHtml.find("#divSignature").removeAttr("data-signature-key");

			html = jHtml[0].outerHTML;
			return html;
		}

		function setStylesEditorHtml(html) {
			var jHtml = $(html);
			jHtml = setFontEditorHtml(jHtml);

			// Strip break after from previous message
			jHtml.find("hr#previousmessagehr").css("break-after", "");

			html = jHtml[0].outerHTML;
			return html;
		}

		function setFontEditorHtml(jHtml) {
			try {

				var fontS = coreData.user.settings.userMailSettings.composeFontSize;
				var fontF = coreData.user.settings.userMailSettings.composeFont;
				if (fontF === 'lucida')
					fontF = 'lucida sans';

				// If there's not exactly one control passed, we're gonna wrap them in a div
				if (jHtml.length !== 1)
					jHtml = $('<div>').append(jHtml);

				if (fontF && !jHtml.css('font-family'))
					jHtml.css('font-family', fontF);
				if (fontS && !jHtml.css('font-size'))
					jHtml.css('font-size', fontS);

			} catch (e) {
				// Ignore errors here. They just won't get default styling
			}

			return jHtml;
		}

		async function checkHideLinkFile() {
			if (!userDataService || !userDataService.user || !userDataService.user.settings) {
				vm.hideLinkFile = true;
			}

			vm.fileStorageEnabled = userDataService.user.settings.features.enableFileStorage;
			vm.cloudStorageEnabled = userDataService.user.settings.features.enableCloudStorage;

			try {
				const success = await coreDataSettings.settingsData.userConnectedServices;
				vm.hideLinkFile = (success.length == 0 || !vm.cloudStorageEnabled) && !vm.fileStorageEnabled;
			} catch (err) {
				//
			}
		}

		async function setupAddressesAndSignatures() {
			$.each(userDataService.user.settings.signatureMappings, function (i, v) {
				if (v.signatures)
					v.signatures.splice(0, 0, { id: "NONE", guid: "NONE", isDefault: false, name: $translate.instant("NONE"), text: "" });
				if (vm.fromAddresses.indexOf(v) === -1) {
					vm.fromAddresses.push(v);
				}
				if (i == 0) {
					vm.selectedFromAddress = v;
				}
			});
			if (vm.message && vm.message.isDraft && vm.message.fromAddress) {
				setFromAddress(vm.message.fromAddress.email);
			} else {

				setFromAddress();
				
				await DetermineToField();
			}

		}

		async function DetermineToField() {

			var input = vm.isSentItems ? (vm.message.from) : (vm.message.to + "," + vm.message.cc + "," + vm.message.bcc);
			
			const success = await $http.post('~/api/v1/settings/determine-from-address/' + (vm.replyForwardMode == MODE.Reply), { input: input });
			vm.ignoreFromAddressChanges = true;
			var toAddresses = success.data.addresses;
			vm.selectedFromAddress = toAddresses[0];
			vm.selectedFromAddress.email = vm.selectedFromAddress.email.toLowerCase();

			// Select from the list if exists
			var temp = $.grep(vm.fromAddresses,
				function (address) {
					return address.email === vm.selectedFromAddress.email;
				});
			if (temp.length > 0)
				vm.selectedFromAddress = temp[0];

			setFromAddress(vm.selectedFromAddress.email);

			temp = $.grep(toAddresses, function (address) {
				for (var i = 0; i < vm.fromAddresses.length; i++) {
					if (address.email.toLowerCase() === vm.fromAddresses[i].email.toLowerCase())
						return false;
				}
				return true;
			});
			angular.forEach(temp, function (address) {
				address.signatures = vm.fromAddresses[0].signatures;
				vm.fromAddresses.push(address);
			});

			var toRemove = vm.fromAddresses.filter(fromAddr =>
				fromAddr === vm.selectedFromAddress ||
				fromAddr.email.indexOf(userDataService.user.username + "@") > -1);

			if (vm.reply && vm.reply.to && vm.reply.to.length > 0) {
				let toAddressesSplit = vm.reply.to.trim().split(regexSplit);
				toAddressesSplit = removeAddresses(toAddressesSplit, toRemove);

				vm.reply.to = toAddressesSplit.join("; ");
				vm.recipientListTo = populateRecipientField(vm.reply.to);
			}

			if (vm.reply && vm.reply.cc && vm.reply.cc.length > 0) {
				let ccAddressesSplit = vm.reply.cc.trim().split(regexSplit);
				ccAddressesSplit = removeAddresses(ccAddressesSplit, toRemove, true);
				vm.reply.cc = ccAddressesSplit.join("; ");
				vm.recipientListCc = populateRecipientField(vm.reply.cc);

				if (ccAddressesSplit.length == 0)
					vm.showCCFields();
			}

			$scope.$applyAsync(); // To make sure debouncedOnFromAddressChanged gets fired
		}

		function removeAddresses(addresses, toRemove, isCC) {
			var results = addresses.reduce((acc, address, idx) => {
				if (address.trim().length > 0 &&
					(!toRemove.some(x => x.email.toLowerCase() === parseEmail(address.toLowerCase())) ||
						(idx === (addresses.length - 1) && acc.length === 0 && !isCC)))
					acc.push(address);
				return acc;
			}, []);

			return results;
		}

		function setFromAddress(emailToMatch) {
			if (emailToMatch == undefined) {
				emailToMatch = userDataService.user.emailAddress;
			}

			$.each(vm.fromAddresses, function (i, v) {
				if (emailToMatch.toLowerCase().indexOf(v.email.toLowerCase()) > -1) {
					vm.selectedFromAddress = v;
					onFromAddressChanged(true);
					return false;
				}
			});
		}

		function stripFriendlyName(formatted) {
			if (!formatted)
				return formatted;

			let address = formatted.replace('""', "").trim();

			if (address.startsWith('"'))
				return address.substr(1, address.lastIndexOf('"') - 1);
			if (address.startsWith("<"))
				return address.substr(1, address.lastIndexOf(">") - 1);
			if (address.endsWith(">") && address.contains("<")) {
				const index = address.indexOf("<");
				address = address.substr(0, index).trim();
				if (!address) {
					const last = address.LastIndexOf('>');
					const length = last - index - 1;
					address = address.substr(index + 1, length);
				}
			}

			return address;
		}

		function stripAddress(formatted) {
			if (!formatted)
				return formatted;

			let address = formatted;

			address = address.toLowerCase().replace("mailto:", "");
			let startIndex = address.indexOf("<");
			const endIndex = address.indexOf(">");
			if (startIndex === -1 || endIndex <= startIndex)
				return address.trim();
			startIndex += 1;

			return address.substr(startIndex, endIndex - startIndex);
		}

		function parseEmail(email) {
			email = email.trim();
			if (!email.contains('<')) return email;

			return email.substring(email.indexOf("<") + 1, email.indexOf(">")).trim();
		}

		function parseDisplayName(email) {
			email = email.trim();
			if (!email.contains('<')) return "";
			var ret = email.substring(0, email.indexOf("<")).trim();
			return _.trim(ret, "\"");
		}

		function parseEmailAndName(email) {
			var display = parseDisplayName(email);
			if (display) {
				return "\"" + display + "\" <" + parseEmail(email) + ">";
			} else {
				return _.trim(parseEmail(email), "<>");
			}
		}

		function getSignatureDivHtml() {
			if (!vm.selectedSignature || !vm.selectedSignature.text)
				return '<div id="divSignature" contenteditable="false"><span> </span></div>';

			var insertText = vm.selectedSignature.text
				.split('\"attlinkedfileid:')
				.join(`"${location.origin}${stSiteRoot}attachedFile?data=`);
			return '<div id="divSignature" contenteditable="false">' + insertText + '</div>';
		}

		function getPreviousMessageForReply() {
			var msgData = angular.copy(vm.message);
			var message = msgData.messagePlainText;

			if (msgData.messageHTML) {
				message = msgData.messageHTML;
				var html = $('<div>' + message + '</div>');
				//Strip base tags from the message because who thought those were a good idea.
				if (html) {
					html.find('base').remove();
					//Remove uneditable from signatures of previous replies.
					html.find("*").removeAttr('contenteditable');
				}
				message = "<br/><hr id='previousmessagehr'>" + getPreviousMessageInfoHtml(msgData) + html.html();
			}
			else if (msgData.isCalendarMessage)
				message = "<br/><hr id='previousmessagehr'>" + getPreviousMessageInfoHtml(msgData);

			return message;
		}

		function getPreviousMessageInfoHtml(message) {
			var retVal = "<span>" +
				"<b>" + $translate.instant("FROM") + "</b>: " + $filter("htmlEscape")(message.from) + "<br/>" +
				"<b>" + $translate.instant("SENT") + "</b>: " + $filter("date")(message.date, 'short') + "<br/>" +
				"<b>" + $translate.instant("TO") + "</b>: " + $filter("htmlEscape")(message.to) + "<br/>";
			if (!message.isCalendarMessage) {
				retVal += (message.cc ?
					("<b>" + $translate.instant("CC") + "</b>: " + $filter("htmlEscape")(message.cc) + "<br/>") :
					"");
			}
			retVal += (message.subject != undefined ?
				"<b>" + $translate.instant("SUBJECT") + "</b>: " + $filter("htmlEscape")(message.subject)
				: "") + "<br/>";
			if (message.isCalendarMessage) {
				retVal += "<b>" + $translate.instant("WHEN") + "</b>: " + $filter("date")(moment.tz(message.meetingInfo.start.dt, message.meetingInfo.start.tz).toDate(), 'short') + "<br/>";
				retVal += "<b>" + $translate.instant("WHERE") + "</b>: " + $filter("htmlEscape")(message.meetingInfo.location);
			}
			retVal += "</span><br/><br/>";
			return retVal;
		}

		// #endregion

		// #region HELPER FUNCTIONS -----------------------------------------------------------------------------------

		function generateGuid() {
			return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		}

		// #endregion
	}

})();
