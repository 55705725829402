(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataContacts", coreDataContacts);

    function coreDataContacts($rootScope, $http, $q, $filter, $log, $translate, userDataService, coreDataSettings, apiCategories, preferencesStorage, apiContactSources, folderNameTranslator) {
        var vm = this;
        var isInitialized = false;
        var isSourcesLoaded = false;
        var isContactsLoaded = false;
        var isContactShared = [];
        var sources = [];
        var contacts = [];
        var initDefer;

        // Public Variables
        vm.reloadOnEnter = false;
        vm.ignoreContactModified = {
            requested: moment(),
            ignored: moment()
        };
        vm.ignoreContactRemoved = {
            requested: moment(),
            ignored: moment()
        };
        vm.listDataCache = {};
        vm.modifyContactCallback = null;

        vm.parameters = {
            get sortField() {
                var value = preferencesStorage.getSessionSortingFilteringParam("contacts", "sortField");
                if (value === undefined) {
                    value = "displayAs"; preferencesStorage.setSessionSortingFilteringParam("contacts", "sortField", value);
                }
                return value;
            },
            set sortField(value) { preferencesStorage.setSessionSortingFilteringParam("contacts", "sortField", value); },

            get isDescending() {
                var value = preferencesStorage.getSessionSortingFilteringParam("contacts", "isDescending");
                if (value === undefined) {
                    value = false;
                    preferencesStorage.setSessionSortingFilteringParam("contacts", "isDescending", false);
                }
                return value;
            },
            set isDescending(value) { preferencesStorage.setSessionSortingFilteringParam("contacts", "isDescending", value); },

            get categoryFilters() {
                const catFilter = apiCategories.getCategoryFilter("contacts");
                return !catFilter ? undefined : catFilter.categoryData;
            },

            get currentView() {
                var value = preferencesStorage.getSortingFilteringParam("contacts", "currentView");
                if (value === undefined) {
                    value = "CARD_VIEW"; preferencesStorage.setSortingFilteringParam("contacts", "currentView", value);
                }
                return value;
            },
            set currentView(value) { preferencesStorage.setSortingFilteringParam("contacts", "currentView", value); },
            get hasCategoryFilter() {
                const catFilter = apiCategories.getCategoryFilter("contacts");
                return !catFilter || !catFilter.allSelected();
            },

            get filterType() {
                return preferencesStorage.getSessionSortingFilteringParam("contacts", "filterType");
            },
            set filterType(value) {
                preferencesStorage.setSessionSortingFilteringParam("contacts", "filterType", value);
            }

        };
        vm.parameters.searchText = "";

        var previousTreeController;
        var sourcesDefer;

        var _sourcesTree = {
            data: [],	// Used by tree directive
            map: [],	// Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };


        // Functions
        vm.init = init;
        vm.reset = reset;
        vm.addContact = addContact;
        vm.addContacts = addContacts;
        vm.deleteContacts = deleteContacts;
        vm.editContact = editContact;
        vm.editContacts = editContacts;
        vm.ensureSourcesLoadedPromise = ensureSourcesLoadedPromise;
        vm.ensureContactsLoadedPromise = ensureContactsLoadedPromise;
        vm.getContactById = getContactById;
        vm.getContactByEmail = getContactByEmail;
        vm.getContacts = function () { return contacts; };
        vm.getFilteredContacts = getFilteredContacts;
        vm.getSources = function () { return sources; };
        vm.listDataProvider = listDataProvider;
        vm.modifyGalContact = modifyGalContact;
        vm.modifyContact = modifyContact;
        vm.removeContacts = removeContacts;
        vm.removeSingleContact = removeSingleContact;
        vm.updateSourceVisibility = updateSourceVisibility;
        vm.uploadNewAvatar = uploadNewAvatar;
        vm.reloadContacts = reloadContacts;
        vm.categories = function () { return apiCategories.getMasterCategories(); };
        vm.resetSources = function () {
            isSourcesLoaded = false;
            apiContactSources.invalidateSources();
        };
        vm.onlyReloadContacts = function () {
            isContactsLoaded = false;
            return loadContacts();
        };
        vm.loadSourcesTree = loadSourcesTree;
        vm.getSourcesTree = getSourcesTree;
        vm.getContact = getContact;
        vm.areContactsLoaded = function () { return isContactsLoaded; };
        vm.getAllContacts = getAllContacts;

        // events
        $rootScope.$on("signalR.mailHub.client.sharesChanged", onShareChanged);

        activate();

        //////////////////

        function activate() {
        }

        function ensureSourcesLoadedPromise() { return loadSources(); }
        function ensureContactsLoadedPromise() { return loadContacts(); }

        function onShareChanged(e, args) {
            if (args && args.shareType && args.shareType !== "contacts") return;
            vm.resetSources();
        }

        function init() {
            // For contacts, init doesn't really do anything.  We just keep this stuff below to keep the same pattern as the other areas
            // technically it would just be replaced with "isInitialized = true; return $q.when();"

            if (initDefer) return initDefer.promise;
            if (isInitialized)
                return $q.when();
            else {
                initDefer = $q.defer();
                initDefer.promise.finally(function () { initDefer = undefined; });
                isInitialized = true;
                initDefer.resolve();
                return initDefer.promise;
            }
        }

        function getContact(shareOwner, shareFolder, contactId) {
            var defer = $q.defer();

            $q.all([
                $http.get("~/api/v1/contacts/get/" + shareOwner + "/" + shareFolder + "/" + contactId),
                apiContactSources.getSources()
            ]).then(onLoaded, defer.reject);

            function onLoaded(results) {
                results[0].data.contact.shareOwner = shareOwner === "null" ? null : shareOwner;
                results[0].data.contact.shareId = shareFolder;
                results[0].data.contact.image = stSiteRoot + results[0].data.contact.image;

                var tempContact = {};
                var sources = results[1];
                var contact = results[0].data.contact;
                for (var i = 0; i < sources.length; i++) {
                    var source = sources[i];
                    if (source.itemID === "gal" && source.itemID === contact.sourceId) {
                        $.extend(tempContact, contact, { sourceId: source.itemID, sourceName: source.displayName, sourcePermission: source.access, isVisible: source.enabled });
                        break;
                    }
                    else if (source.itemID === contact.sourceId &&
                        ((source.ownerUsername && contact.shareOwner)
                            ? (source.ownerUsername.toUpperCase() === contact.shareOwner.toUpperCase())
                            : (source.ownerUsername === contact.shareOwner))) {
                        $.extend(tempContact, contact, { sourceOwner: source.ownerUsername, sourceId: source.itemID, sourceName: source.displayName, sourcePermission: source.access, isVisible: source.enabled });
                        break;
                    }
                }
                if (results[0].data.attachedFiles)
                    $.extend(tempContact, { attachedFiles: results[0].data.attachedFiles });

                defer.resolve(tempContact);
                //defer.resolve(results[0].data.contact);
            }
            return defer.promise;
        }

        function reloadContacts() {
            reset();
            return init();
        }

        function reset() {
            isInitialized = false;
            isSourcesLoaded = false;
            isContactsLoaded = false;
            sources.length = 0;
            contacts.length = 0;
            vm.listDataCache = {};
        }

        function listDataProvider(obj) {
            //TODO: Manage loading contacts dynamically instead of pulling from a full set of data
            var subset = [];
            var j = 0;
            var c = vm.getFilteredContacts();
            for (var i = obj.start; i < (obj.start + obj.take); i++) {
                //Note: splicing will make a copy, I want a reference.
                if (c[i] == undefined) {
                    break;
                }
                subset[j++] = c[i];
            }
            obj.defer.resolve(subset);
            return obj.defer.promise;
        }

        function filterByCategoryCallback(contact) {
            const categoryFilters = vm.parameters.categoryFilters;
            if (contact.categories && contact.categories.some(c => c.selected)) {
                return contact.categories.some(cat => cat.selected &&
                    categoryFilters.some(catFilter => catFilter.selected && catFilter.name === cat.name));

            } else {
                return categoryFilters.some(cat => cat.noCategory && cat.selected);
            }

        }

        function applyCategoryFiltering() {
            if (!vm.parameters.hasCategoryFilter)
                return contacts;

            return $.grep(contacts, function (contact) { return filterByCategoryCallback(contact); });
        }

        function applyNonCategoryFiltering(filteredContactsCategory) {
            if (vm.parameters.filterType === undefined)
                return filteredContactsCategory;

            return $.grep(filteredContactsCategory, function (contact) { return filterCallback(contact); });
        }

        function filterCallback(contact) {
            const filter = vm.parameters.filterType;
            if (filter === undefined || filter.length === 0)
                return true;

            if (contact.hasAttachments && filter === 1)
                return true;

            return false;
        }

        function getFilteredContacts() {
            var filteredContacts = applyCategoryFiltering();
            filteredContacts = applyNonCategoryFiltering(filteredContacts);
            filteredContacts = $filter("filter")(filteredContacts, searchPredicate);
            filteredContacts = $filter("orderBy")(filteredContacts, vm.parameters.sortField, vm.parameters.isDescending);
            return filteredContacts;
        }

        function loadSources() {
            var defer = $q.defer();
            if (isSourcesLoaded) {
                defer.resolve();
            } else {
                getSources()
                    .then(function () {
                        isContactShared = [];

                        coreDataSettings.resetResources();
                        $q.all([coreDataSettings.settingsData.resources,
                        coreDataSettings.settingsData.mappedResources])
                            .then(function (success) {
                                var resourceData = success[0];
                                for (var i = 0; i < resourceData.length; i++) {
                                    if (resourceData[i].shareType === 2) {
                                        isContactShared[resourceData[i].resourceName] = resourceData[i].permissions > 0;
                                    }
                                }
                                defer.resolve();
                            }, function () {
                                defer.resolve();
                            });

                        isSourcesLoaded = true;
                        isContactsLoaded = false;
                    }, function (failure) {
                        isSourcesLoaded = false;
                        isContactsLoaded = false;
                        defer.reject(failure);
                    });
            }
            return defer.promise;
        }

        var getSourcesDefer = null;
        function getSources() {
            if (getSourcesDefer) return getSourcesDefer.promise;

            getSourcesDefer = $q.defer();

            $http
                .get("~/api/v1/contacts/sources")
                .then(onGetSourcesSuccess, onGetSourcesFailure);

            return getSourcesDefer.promise;

            function onGetSourcesSuccess(result) {
                sources = result.data.sharedLists;

                for (var iter = 0; iter < sources.length; ++iter) {
                    if (!sources[iter].displayName) sources[iter].displayName = "MY_CONTACTS";
                    if (sources[iter].displayName.toUpperCase() === "MY CONTACTS" || sources[iter].displayName === "MY_CONTACTS")
                        sources[iter].untranslatedName = "MY_CONTACTS";

                }

                preferencesStorage.cleanSourceVisibilities("contacts", sources);
                angular.forEach(sources, function (source) {
                    source.enabled = preferencesStorage.getSourceVisibility("contacts", source);
                });

                sources.sort(apiContactSources.defaultContactSourceComparer);

                getSourcesDefer.resolve();
                getSourcesDefer = null;
            }

            function onGetSourcesFailure(result) {
                getSourcesDefer.reject(result);
                getSourcesDefer = null;
                return result;
            }
        }

        var getAllContactsDefer = null; 
        async function getAllContacts() {
            await loadSources();
            if (getAllContactsDefer) return getAllContactsDefer.promise;
            getAllContactsDefer = $q.defer();
            var mapped = $.map(sources, function (s) {
                return {
                    owner: s.ownerUsername,
                    id: s.itemID,
                    sourceName: s.displayName
                };
            });
            var params = {
                sources: mapped,
                searchParams: {
                    categories: null,
                    showNonCategorized: true,
                    skip: 0,
                    take: 0,
                    search: null,
                    sortDescending: false
                }
            };
            $http
                .post("~/api/v1/contacts/contacts-all", JSON.stringify(params))
                .then(onSuccess, onFailure)
                .finally(onFinally);

            function onSuccess(success) {
                getAllContactsDefer.resolve(success.data);
            }

            function onFailure(failure) {
                getAllContactsDefer.reject(failure);
            }

            function onFinally() {
                getAllContactsDefer = null;
            }

            return getAllContactsDefer.promise;
        }

        function loadContacts() {
            var defer = $q.defer();

            loadSources().then(function () {
                if (isContactsLoaded) {
                    defer.resolve();
                    return defer.promise;
                }

                contacts.length = 0;

                var promises = [];
                angular.forEach(sources, function (source) {
                    if (source.enabled)
                        promises.push(getContacts(source));
                });

                return $q.all(promises)
                    .then(function () {
                        isContactsLoaded = true;
                        defer.resolve();
                    }, function (failure) {
                        isContactsLoaded = false;
                        defer.reject(failure);
                    });
            });

            return defer.promise;
        }

        var getContactsDefer = {};
        function getContacts(source) {
            if (getContactsDefer.hasOwnProperty(source.itemID)) {
                return getContactsDefer[source.itemID].promise;
            }
            getContactsDefer[source.itemID] = $q.defer();
            if (source.ownerUsername === "") {
                $http.get("~/api/v1/contacts/domain/")
                    .then(function (success) {
                        var details = [];
                        angular.forEach(success.data.contacts, function (detail) {
                            detail.image = stSiteRoot + detail.image;
                            var tempDetail = {};
                            $.extend(tempDetail, detail, {
                                sourceOwner: detail.sourceOwner || userDataService.user.domain,
                                sourceId: source.itemID,
                                sourceName: source.displayName,
                                sourcePermission: source.access,
                                isVisible: source.enabled,
                            });
                            if (!tempDetail.id) { tempDetail.id = coreDataSettings.generateNewShortGuid(); } //This id is just for interface identifcation purpose if the contact doesn't have an id (aliases).

                            // Domain contacts really shouldn't show categories in webmail
                            // coreDataCategories.addRgbColorsToCategories(tempDetail.categories);
                            tempDetail.categoriesString = apiCategories.generateNameString(tempDetail.categories);

                            details.push(tempDetail);
                        });
                        contacts = contacts.concat(details);
                        isContactsLoaded = true;
                        getContactsDefer[source.itemID].resolve();
                        delete getContactsDefer[source.itemID];
                    }, function (failure) {
                        getContactsDefer[source.itemID].reject(failure);
                        delete getContactsDefer[source.itemID];
                    });
            } else {
                $http.get('~/api/v1/contacts/address-book/' + source.ownerUsername + '/' + source.itemID + '/')
                    .then(function (success) {
                        var details = [];
                        angular.forEach(success.data.contacts, function (detail) {
                            var tempDetail = {};
                            detail.image = stSiteRoot + detail.image;
                            $.extend(tempDetail, detail, {
                                sourceOwner: source.ownerUsername,
                                sourceId: source.itemID,
                                sourceName: source.displayName,
                                sourcePermission: source.access,
                                isVisible: source.enabled,
                            });
                            if (!tempDetail.id) { tempDetail.id = coreDataSettings.generateNewShortGuid(); } //This id is just for interface identifcation purpose if the contact doesn't have an id (aliases).

                            if (source.ownerUsername)
                                apiCategories.addRgbColorsToCategories(source.ownerUsername, tempDetail.categories);
                            else
                                tempDetail.categories = [];
                            tempDetail.categoriesString = apiCategories.generateNameString(tempDetail.categories);

                            details.push(tempDetail);
                        });
                        contacts = contacts.concat(details);
                        isContactsLoaded = true;
                        getContactsDefer[source.itemID].resolve();
                        delete getContactsDefer[source.itemID];
                    }, function (failure) {
                        getContactsDefer[source.itemID].reject(failure);
                        delete getContactsDefer[source.itemID];
                    });
            }
            return getContactsDefer[source.itemID].promise;
        }

        function getContactById(id, source) {
            source = source || userDataService.user.username;
            source = source.split("@")[0];
            var temp = $.grep(contacts, function (card) {
                if (card) return card.id === id;// && card.sourceOwner === source
            });
            if (temp.length > 0)
                return temp[0];

            return null;
        }

        function getContactByEmail(email) {
            email = email.toLowerCase();
            var temp = $.grep(contacts, function (contact) {
                return $.grep(contact.emailAddressList || [], function (e) { return e ? e.toLowerCase().indexOf(email) > -1 : false; }).length > 0;
            });
            if (temp.length > 0)
                return temp[0];
            return undefined;
        }

        function modifyGalContact() {
            var contactSource = $.grep(sources, function (source) {
                return source.itemID === "gal";
            });
            if (contactSource.length > 0 && contactSource[0].enabled) {
                contacts = $.grep(contacts, function (contact) {
                    if (contact)
                        return contact.sourceId === contactSource[0].itemID;
                }, true);
                var promises = [];
                promises.push(getContacts(contactSource[0]));

                $q.all(promises)
                    .then(function () {
                        if (vm.modifyContactCallback)
                            vm.modifyContactCallback();
                        $rootScope.$broadcast("contactsRefresh");
                    }, function () {

                    });
            }
        }

        function modifyContact(owner, addressFolder) {
            var parsedOwner = owner.split("@");
            parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : "";
            if (parsedOwner === "") {
                return;
            }
            var contactSource = $.grep(sources, function (source) { return source.ownerUsername === parsedOwner; });
            if (contactSource.length > 0 && contactSource[0].enabled) {
                contacts = $.grep(contacts, function (contact) {
                    return contact && contact.sourceName === contactSource[0].displayName;
                }, true);
                var promises = [];
                promises.push(getContacts(contactSource[0]));

                $q.all(promises)
                    .then(function () {
                        if (vm.modifyContactCallback)
                            vm.modifyContactCallback();
                        $rootScope.$broadcast("contactsRefresh");
                    }, function () { });
            }
        }

        function deleteContacts(owner, ids) {
            var parsedOwner = owner.split("@");
            parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : "";
            if (parsedOwner === "") { return; }
            var temp = [];
            for (var i = 0; i < contacts.length; ++i) {
                if (contacts[i].sourceOwner === parsedOwner && ids.indexOf(contacts[i].id) === -1)
                    temp.push(contacts[i]);
            }
            contacts = temp;
            $rootScope.$broadcast("contactsRefresh");
        }

        function addContacts(contactsToAdd, source, skipSignalR) {
            var chunkSize = 1000;
            var defer = $q.defer();
            var promises = [];
            for (var i = 0; i < Math.ceil(contactsToAdd.length / chunkSize); ++i) {
                var c = contactsToAdd.slice(i * chunkSize, (i + 1) * chunkSize);
                var params = JSON.stringify({ skipSignalR: skipSignalR || false, contacts: c });
                promises.push(apiCall(params));
            }

            $q
                .all(promises)
                .then(function (success) {
                    var numAdded = 0;
                    if (source.enabled) {
                        for (var i = 0; i < success.length; ++i) {
                            for (var j = 0; j < success[i].data.contacts.length; ++j) {
                                var contact = success[i].data.contacts[j];
                                var newContact = $.extend({}, contact, { sourceOwner: source.ownerUsername, sourceId: source.itemID, sourceName: source.displayName, sourcePermission: source.access, isVisible: source.enabled });
                                contacts.push(newContact);
                                numAdded++;
                            }
                        }
                    }
                    defer.resolve(numAdded);
                }, function (failure) {
                    defer.reject(failure);
                });

            function apiCall(p) {
                return $http.post("~/api/v1/contacts/contacts-put/" + source.ownerUsername + "/" + source.folderId, p);
            }

            return defer.promise;
        }

        function addContact(contact) {
            var defer = $q.defer();
            var sourceOwner = contact.sourceOwner;
            var folderUid = contact.sourceId;
            var permission = contact.sourcePermission;
            var params = JSON.stringify(contact);
            vm.ignoreContactModified.requested = moment();

            $http
                .post("~/api/v1/contacts/contact-put/" + sourceOwner + "/" + folderUid, params)
                .then(function (success) {
                    if (contact.source.enabled) {
                        for (var i = 0; i < success.data.contacts.length; ++i) {
                            $.extend(success.data.contacts[i], success.data.contacts[i], { sourceOwner: sourceOwner, sourceId: contact.source.itemID, sourceName: contact.source.displayName, sourcePermission: contact.source.access, isVisible: contact.source.enabled });
                            contacts.push(success.data.contacts[i]);
                        }
                    }
                    defer.resolve(success);
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function editContacts(contactsToEdit, source) {
            var defer = $q.defer();
            var params = JSON.stringify({ contacts: contactsToEdit });
            $http
                .post("~/api/v1/contacts/contacts-put/" + source.ownerUsername + "/" + source.folderId, params)
                .then(function () {
                    if (source.enabled) {
                        angular.forEach(contactsToEdit, function (newContact) {
                            var oldContact = vm.getContactById(newContact.id, source.ownerUsername);
                            if (oldContact) oldContact = newContact;
                        });
                    }
                    defer.resolve();
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function editContact(contact) {
            var defer = $q.defer();
            var params = JSON.stringify({ contact: contact });
            vm.ignoreContactModified.requested = moment();
            $http
                .post("~/api/v1/contacts/contact/" + contact.sourceOwner + "/" + contact.sourceId, params)
                .then(function () {
                    var temp = $.grep(contacts,
                        function (c) {
                            return c.id === contact.id &&
                                c.sourceOwner === contact.sourceOwner &&
                                c.sourceId === contact.sourceId;
                        });
                    if (temp.length > 0) {
                        var index = contacts.indexOf(temp[0]);
                        contacts[index] = contact;
                    }
                    if (vm.modifyContactCallback)
                        vm.modifyContactCallback();

                    defer.resolve();
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function removeSingleContact(id, sourceId, sourceOwner) {
            var defer = $q.defer();

            var contactsToRemove = [];
            if (sourceId !== "gal") {
                contactsToRemove.push({ sourceOwner: sourceOwner, sourceId: sourceId, id: id });
            }

            vm.ignoreContactRemoved.requested = moment();
            var params = JSON.stringify(contactsToRemove);
            $http.post("~/api/v1/contacts/delete-bulk", params)
                .then(function () {
                    angular.forEach(contactsToRemove, function (contact) {
                        contacts = $.grep(contacts, function (t) { return t.id === contact.id && t.sourceId === contact.sourceId && t.sourceOwner === contact.sourceOwner; }, true);
                    });
                    $rootScope.$broadcast("contactsRefresh");
                    defer.resolve();
                }, function (failure) {
                    defer.reject(failure);
                });

            return defer.promise;
        }

        function removeContacts(ids) {
            var defer = $q.defer();
            if (!angular.isArray(ids))
                defer.reject($filter("translate")("ERROR"));
            else {
                var contactsToRemove = [];
                var galCount = 0;

                for (var i = 0; i < contacts.length; ++i) {
                    if (ids.indexOf(contacts[i].id) > -1) {
                        var contact = contacts[i];
                        if (contact.sourceId === "gal")
                            galCount++;
                        else
                            contactsToRemove.push({ sourceOwner: contact.sourceOwner, sourceId: contact.sourceId, id: contact.id });
                    }
                }

                if (contactsToRemove.length === 0) {
                    if (galCount === 0) {
                        defer.reject($filter("translate")("ERROR_NO_CARDS"));
                    } else {
                        defer.reject($filter("translate")("ERROR_CANNOT_DELETE_GAL"));
                    }
                }
                else {
                    vm.ignoreContactRemoved.requested = moment();
                    var params = JSON.stringify(contactsToRemove);
                    $http.post("~/api/v1/contacts/delete-bulk", params)
                        .then(function () {
                            var dict = [];
                            var i;
                            for (i = 0; i < contactsToRemove.length; ++i) {
                                var contact = contactsToRemove[i];
                                if (contact.sourceId === "gal")
                                    continue;
                                if (!dict[contact.sourceId])
                                    dict[contact.sourceId] = [];
                                if (!dict[contact.sourceId][contact.sourceOwner])
                                    dict[contact.sourceId][contact.sourceOwner] = [];
                                if (dict[contact.sourceId][contact.sourceOwner].indexOf(contact.id) === -1)
                                    dict[contact.sourceId][contact.sourceOwner].push(contact.id);
                            }

                            for (i = contacts.length - 1; i >= 0; --i) {
                                if (dict[contacts[i].sourceId] && dict[contacts[i].sourceId][contacts[i].sourceOwner] && dict[contacts[i].sourceId][contacts[i].sourceOwner].indexOf(contacts[i].id) > -1)
                                    contacts.splice(i, 1);
                            }

                            $rootScope.$broadcast("contactsRefresh");
                            defer.resolve();
                        }, function (failure) {
                            defer.reject(failure);
                        });
                }
            }
            return defer.promise;
        }

        function uploadNewAvatar() {
            var defer = $q.defer();
            try {
                defer.resolve();
            } catch (er) {
                defer.reject(er);
            }
            return defer.promise;
        }

        async function updateSourceVisibility(source, newValue) {
            if (typeof newValue !== 'undefined' && newValue !== null)
                source.enabled = newValue;

            preferencesStorage.setSourceVisibility("contacts", source);

            if (source.enabled) {
                await getContacts(source);
                return;
            }
            else {
                var sourceOwner = source.ownerUsername;
                contacts = $.grep(contacts, function (contact) {
                    if (contact.sourceId === source.itemID) {
                        if (contact.sourceOwner === null && !sourceOwner)
                            return true;
                        else if (!contact.sourceOwner && sourceOwner === null)
                            return true;
                        else if (contact.sourceId === "gal" && source.itemID === "gal" && source.sharedResourceType === 0)
                            return true;
                        return contact.sourceOwner === sourceOwner;
                    }
                }, true);
            }
        }

        function searchPredicate(contact) {
            return comparer(contact.email) ||
                comparerEmails(contact.emailAddressList) ||
                comparer(contact.displayAs) ||
                comparer(contact.firstName) ||
                comparer(contact.middleName) ||
                comparer(contact.lastName) ||
                comparer(contact.fullName) ||
                comparerPhones(contact.phoneNumberList) ||
                comparer(contact.jobTitle) ||
                comparer(contact.busCity) ||
                comparer(contact.busState) ||
                comparer(contact.busStreet) ||
                comparer(contact.busZip) ||
                comparer(contact.busCountry) ||
                comparer(contact.company) ||
                comparer(contact.companyDepartment) ||
                comparer(contact.companyOffice) ||
                comparer(contact.companyUrl) ||
                comparer(contact.homeCity) ||
                comparer(contact.homeState) ||
                comparer(contact.homeStreet) ||
                comparer(contact.homeZip) ||
                comparer(contact.homeCountry) ||
                comparer(contact.title) ||
                comparer(contact.additionalInfo) ||
                comparer(contact.webPage) ||
                comparer(contact.nickname) ||
                comparer(contact.spouseName) ||
                comparer(contact.assistantName) ||
                comparer(contact.managerName) ||
                comparer(contact.profession) ||
                comparer(contact.im);
        }

        function comparer(value) {
            if (!value) return false;
            var search = vm.parameters.searchText || "";
            return value.toLowerCase().indexOf(search.toLowerCase()) > -1;
        }

        function comparerEmails(emails) {
            if (!angular.isArray(emails))
                return false;

            for (var index = 0, len = emails.length; index < len; ++index) {
                if (comparer(emails[index]))
                    return true;
            }
            return false;
        }

        function comparerPhones(phones) {
            if (!angular.isArray(phones))
                return false;

            for (var index = 0, len = phones.length; index < len; ++index) {
                if (comparer(phones[index].number))
                    return true;
            }
            return false;
        }

        // Load Sources Tree
        function loadSourcesTree(treeController, forceReload) {
            if (sourcesDefer)
                return sourcesDefer.promise;
            sourcesDefer = $q.defer();

            if ((treeController == null) && previousTreeController)
                treeController = previousTreeController;

            if (forceReload || _sourcesTree.data.length === 0) {
                loadSourcesTreeData(sourcesDefer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            }
            else {
                var b = _sourcesTree.map[_sourcesTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    sourcesDefer.resolve(false);
                }
                sourcesDefer.resolve(true);
            }

            sourcesDefer.promise.finally(function () {
                sourcesDefer = null;
            });

            return sourcesDefer.promise;
        }

        function loadSourcesTreeData(defer, treeController) {
            loadSources().then(function () {
                let newData = [];
                let newMap = [];

                $.each(sources, function (i, val) {
                    if (val.ownerUsername == userDataService.user.username) {
                        val.isBeingShared = isContactShared[val.itemID] || false;
                    }
                    newData.push(loadSourcesTreeBranch(val, newMap));
                });

                _sourcesTree.data = newData;
                _sourcesTree.map = newMap;
                defer.resolve();
            });
        }

        function loadSourcesTreeBranch(val, newMap) {
            // Load data for this branch.

            if (val.itemID === "gal") {
                val.displayName = "GLOBAL_ADDRESS_LIST";
                name = val.displayName;
                val.untranslatedName = $translate.instant("GLOBAL_ADDRESS_LIST");
            }
            var name = val.untranslatedName ? $filter('translate')(val.untranslatedName) : val.displayName;

            var branch = {
                label: name,
                data: {
                    "folderId": val.folderId,
                    "id": val.displayName,
                    "isShared": val.ownerUsername !== userDataService.user.username || val.isBeingShared,
                    "isSharedByOther": val.ownerUsername !== userDataService.user.username || val.isDomainResource,
                    "isDomainResource": val.isDomainResource,
                    "showEye": true,
                    "isVisible": val.enabled,
                    "source": val,
                    "isBeingShared": val.isBeingShared,
                    "untranslatedName": val.itemID === "gal" ? "GLOBAL_ADDRESS_LIST" : undefined,
                    "isGal": val.itemID === "gal",
                    "isPrimary": val.isPrimary,
                }
            };
            newMap[branch.data.key] = branch;
            return branch;
        }


        // Getters
        function getSourcesTree() {
            return _sourcesTree;
        }

    }

})();